import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Link } from "react-router-dom";
import { BreadcrumbsWrapper } from "./breadcrumbs.atom.style";

interface IProps {
  breadcrumbs: {
    text: string;
    path?: string;
  }[];
  backgroundColor?: string;
}

export const BreadcrumbsAtom: React.FC<IProps> = (props) => {
  return (
    <BreadcrumbsWrapper backgroundColor={props.backgroundColor}>
      <Breadcrumb>
        {props.breadcrumbs.map((m, i) =>
          m.path ? (
            <Breadcrumb.Item key={`Breadcrumb.Item-${i}`} linkAs={"div"}>
              <Link to={m.path}>{m.text}</Link>
            </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item key={"Breadcrumb.Item-active"} active>
              {m.text}
            </Breadcrumb.Item>
          )
        )}
      </Breadcrumb>
    </BreadcrumbsWrapper>
  );
};
