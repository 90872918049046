import React, { useEffect } from "react";
import { MaganeStockZeroKmBasePage } from "../..";
import { useRenaveContext } from "../../../../hooks";
import { RenaveMenuStepsZeroKmEnum } from "../../../../models";
import { CheckOutZeroKmOrganism } from "../../../organisms/check-out-zero-km/check-out-zero-km.organism";

export const CheckOutZeroKmPage: React.FC = () => {
  const {
    setMenuSelectedStepZeroKmContext,
    menuSelectedStepZeroKmContext,
    menuCurrentStepZeroKmContext,
  } = useRenaveContext();

  useEffect(() => {
    setMenuSelectedStepZeroKmContext(
      RenaveMenuStepsZeroKmEnum.CheckOutVehicleZeroKm
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MaganeStockZeroKmBasePage>
      <CheckOutZeroKmOrganism
        viewMode={
          !!menuSelectedStepZeroKmContext &&
          menuSelectedStepZeroKmContext < menuCurrentStepZeroKmContext
        }
      />
    </MaganeStockZeroKmBasePage>
  );
};
