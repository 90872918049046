import { Form } from "react-bootstrap";
import styled from "styled-components";

export const CreditAcquisitionStyledForm = styled(Form)`
  label {
    font-family: ${(props) => props.theme.fontFamily.OpenSans};
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;

    color:  ${(props) => props.theme.colors.Dark};
  }
`;

export const CreditAcquisitionTextTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  color: ${(props) => props.theme.colors.BlueNavy};
`;
