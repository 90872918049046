import { TypesHelper } from "c4u-web-components";
import { TypeCrvEnum } from "../..";

export interface IGetRestrictionRequest {
  plate: string;
  renavam: string;
  numberCrv: string;
  typeCrv?: TypeCrvEnum;
  capable?: boolean;
}

export class GetRestrictionRequest {
  plate: string | null;
  renavam: string | null;
  numberCrv: string | null;
  typeCrv: number | null;
  capable: boolean | null;

  constructor({
    plate,
    renavam,
    numberCrv,
    typeCrv,
    capable,
  }: IGetRestrictionRequest) {
    this.plate = TypesHelper.toStringNull(plate.replace("-", ""));
    this.renavam = TypesHelper.toStringOnlyNumbersNull(renavam);
    this.numberCrv = TypesHelper.toStringOnlyNumbersNull(numberCrv);
    this.typeCrv = TypesHelper.toNumberNull(typeCrv);
    this.capable = TypesHelper.toBooleanNull(capable);
  }
}
