import styled from "styled-components";

export const CreditPacksSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  gap: 12px;
`;

export const CreditPacksSectionTitle = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;

  color: ${(props) => props.theme.colors.Dark};
`;

export const CreditPacksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  gap: 24px;
`;
