import { ZipcodeAddress } from "c4u-web-components";
import { useRequest, viacepHttp } from "./../config";

export const useZipcodeAddress = () => {
  const { get } = useRequest(viacepHttp, "ws", {});

  const getAddressByZipcode = async (zipcode: string) => {
    try {
      const zipcodeNumbers = zipcode?.replace(/[^\d]/g, "");
      const { data } = await get<ZipcodeAddress>(`${zipcodeNumbers}/json/`);
      return data;
    } catch (error) {
      console.log(error);
    }

    return {} as ZipcodeAddress;
  };

  return {
    getAddressByZipcode,
  };
};
