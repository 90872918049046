import React from "react";
import { useTranslation } from "react-i18next";

import {
  ContentCardRegister,
  TitleCardRegister,
  WrapperCard,
  DescriptioCardRegister,
} from "./card-info.atom.style";

interface IProps {
  title: string;
  description: string[];
}

export const CardInfoAtom: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <>
      <WrapperCard>
        <ContentCardRegister>
          <TitleCardRegister>{t(props.title)}</TitleCardRegister>

          <DescriptioCardRegister>
            <ul>
              {props.description.map((n, i) => (
                <li key={`list-item-${i}`}>{t(n)}</li>
              ))}
            </ul>
          </DescriptioCardRegister>
        </ContentCardRegister>
      </WrapperCard>
    </>
  );
};
