import { ICreatePasswordlessRequest } from "c4u-web-components";
import { FormikErrors } from "formik";
import { useContext } from "react";
import { SessionContext } from "../../contexts";

export interface SessionProviderState {
  clickButtonGenericWarningModal: () => void;
  clickButtonGenericErrorModal: () => void;
  clickButtonGenericSuccessModal: () => void;
  onClickButtonGenericSuccessModal: () => void;
  genericModalMsg: string | null;
  genericErrorModal: boolean;
  showGenericErrorModal: (
    text?: string | null,
    onClose?: (() => void) | null,
    title?: string | null,
    textHeader?: string | null
  ) => void;
  onClickButtonGenericErrorModal: (onClickButton: () => void) => void;
  onClickButtonGenericWarningModal: (onClickButton: () => void) => void;
  hideGenericErrorModal: () => void;
  genericWarningModal: boolean;
  showGenericWarningModal: (
    text?: string | null,
    onClose?: (() => void) | null,
    title?: string | null
  ) => void;
  hideGenericWarningModal: () => void;
  genericSuccessModal: boolean;
  showGenericSuccessModal: (
    text?: string | null,
    onClose?: (() => void) | null,
    title?: string | null,
    textHeader?: string | null
  ) => void;
  hideGenericSuccessModal: () => void;
  handleFormikError: <Values>(
    exception: any,
    setErrors?: FormikErrors<Values>,
    msg?: string
  ) => string | undefined;
  handleFormikException: <Values>(
    exception: any,
    setErrors?: FormikErrors<Values>,
    msg?: string
  ) => string | undefined;
  titleErrorModal?: string;
  textHeaderErrorModal?: string;
  titleWarningModal?: string;
  titleSuccessModal?: string;
  textHeaderSucessModal?: string;
  usernamePawordlessContext: ICreatePasswordlessRequest;
  setUsernamePawordlessContext: React.Dispatch<ICreatePasswordlessRequest>;
}

export const useSessionContext = () => {
  const context = useContext<SessionProviderState>(SessionContext);

  if (!context) {
    throw new Error("Empty context");
  }

  return context;
};
