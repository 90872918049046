import { TypesHelper } from "c4u-web-components";

interface IPutEntryCancellationRequest {
  id: number;
}

export class PostEntryCancellationRequest {
  id: number | null;

  constructor({ id }: IPutEntryCancellationRequest) {
    this.id = TypesHelper.toNumberNull(id);
  }
}
