import {
  ButtonTertiary,
  FormikControlAtom,
  MaskHelper,
} from "c4u-web-components";
import { useFormik } from "formik";
import React from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useRenave, useSessionContext } from "../../../hooks";
import {
  IDealershipRegisterForm,
  PostDealershipRequest,
} from "../../../models";
import {
  WrapperSectionRegister,
  TitleSectionRegisterDiv,
  LabelField,
} from "./register-dealership-form.molecule.style";
import { RegisterDealershipValidation } from "./register-dealership-form.molecule.validation";

interface IProps {
  initialValues: IDealershipRegisterForm;
  onSuccess: () => void;
}

export const RegisterDealershipFormMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { postDealership } = useRenave();
  const { showGenericErrorModal, showGenericSuccessModal } =
    useSessionContext();

  const formik = useFormik<IDealershipRegisterForm>({
    initialValues: props.initialValues ?? ({} as IDealershipRegisterForm),
    onSubmit: async (values, { setErrors }) => {
      try {
        await postDealership(
          new PostDealershipRequest({
            phone: values.phone,
            updateMyPhone: values.updateMyPhone,
          })
        );

        showGenericSuccessModal(
          t("WaitConsultant"),
          props.onSuccess,
          t("SucessDataConfirm")
        );
      } catch (e) {
        const message =
          (e as any)?.message.length !== 0
            ? (e as any)?.message
            : t("ErrorRegisterDealership");

        showGenericErrorModal(message, null, t("Error"));
      }
    },
    validationSchema: RegisterDealershipValidation(),
    validateOnBlur: true,
    validateOnChange: false,
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <WrapperSectionRegister>
        <TitleSectionRegisterDiv>
          {t("CheckRegistrationData")}
        </TitleSectionRegisterDiv>
        <Form.Row>
          <FormikControlAtom
            label={<LabelField>{t("UserName")}</LabelField>}
            property={"nameUser"}
            formik={formik}
            translate={t}
            md={6}
            disabled
          />

          <FormikControlAtom
            label={<LabelField>{t("Email")}</LabelField>}
            property={"email"}
            formik={formik}
            translate={t}
            md={6}
            disabled
          />
        </Form.Row>
      </WrapperSectionRegister>
      <hr />
      <WrapperSectionRegister>
        <TitleSectionRegisterDiv>{t("DealershipData")}</TitleSectionRegisterDiv>

        <Form.Row>
          <FormikControlAtom
            label={<LabelField>{t("Cnpj")}</LabelField>}
            property={"cnpj"}
            formik={formik}
            translate={t}
            md={4}
            disabled
            mask={MaskHelper.Cnpj}
          />

          <FormikControlAtom
            label={<LabelField>{t("PhoneNumber")}</LabelField>}
            property={"dealershipPhone"}
            formik={formik}
            translate={t}
            md={4}
            disabled
            mask={MaskHelper.Cellphone}
          />
        </Form.Row>

        <Form.Row>
          <FormikControlAtom
            label={<LabelField>{t("FantasyName")}</LabelField>}
            property={"name"}
            formik={formik}
            translate={t}
            md={6}
            disabled
          />
          <FormikControlAtom
            label={<LabelField>{t("CompanyName")}</LabelField>}
            property={"legalName"}
            formik={formik}
            translate={t}
            md={6}
            disabled
          />
        </Form.Row>
      </WrapperSectionRegister>
      <hr />
      <WrapperSectionRegister>
        <Form.Row>
          <FormikControlAtom
            label={<LabelField>{t("CEP")}</LabelField>}
            property={"zipCode"}
            formik={formik}
            translate={t}
            md={4}
            disabled
          />
        </Form.Row>

        <Form.Row>
          <FormikControlAtom
            label={<LabelField>{t("StreetName")}</LabelField>}
            property={"street"}
            formik={formik}
            translate={t}
            md={4}
            disabled
          />
          <FormikControlAtom
            label={<LabelField>{t("Number")}</LabelField>}
            property={"number"}
            formik={formik}
            translate={t}
            md={4}
            disabled
          />
          <FormikControlAtom
            label={<LabelField>{t("Complement")}</LabelField>}
            property={"complement"}
            formik={formik}
            translate={t}
            md={4}
            disabled
          />
        </Form.Row>

        <Form.Row>
          <FormikControlAtom
            label={<LabelField>{t("Neighborhood")}</LabelField>}
            property={"neighborhood"}
            formik={formik}
            translate={t}
            md={4}
            disabled
          />
          <FormikControlAtom
            label={<LabelField>{t("City")}</LabelField>}
            property={"city"}
            formik={formik}
            translate={t}
            md={4}
            disabled
          />
          <FormikControlAtom
            label={<LabelField>{t("State")}</LabelField>}
            property={"state"}
            formik={formik}
            translate={t}
            md={4}
            disabled
          />
        </Form.Row>
      </WrapperSectionRegister>
      <hr />
      <WrapperSectionRegister className="mt-4">
        <TitleSectionRegisterDiv>
          {t("UpdateYourPhone")}
        </TitleSectionRegisterDiv>

        <Form.Row>
          <FormikControlAtom
            label={<LabelField>{t("PhoneNumber")}</LabelField>}
            property={"phone"}
            formik={formik}
            translate={t}
            md={4}
            mask={MaskHelper.Cellphone}
          />
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={8} controlId="updateMyPhone">
            <Form.Check
              type="checkbox"
              label={t("UpdateMyPhoneQuestion")}
              onChange={formik.handleChange}
              checked={formik.values.updateMyPhone}
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={8} controlId="confirmData">
            <Form.Check
              type="checkbox"
              label={t("IConfirmInformation")}
              onChange={formik.handleChange}
              checked={formik.values.confirmData}
            />
          </Form.Group>
        </Form.Row>
      </WrapperSectionRegister>

      <Form.Row className="d-flex align-itens-center mt-4 mb-5">
        <ButtonTertiary
          onClick={() => formik.handleSubmit()}
          sizex={"md"}
          className="align"
          disabled={!formik.values.confirmData}
        >
          {t("ConfirmData")}
        </ButtonTertiary>
      </Form.Row>
    </Form>
  );
};
