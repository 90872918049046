import React, { useEffect } from "react";
import { ManageStockBasePage } from "../..";
import { useRenaveContext } from "../../../../hooks";
import { RenaveMenuStepsEnum } from "../../../../models";
import { VehicleCheckedOutOrganism } from "../../../organisms";

export const VehicleCheckedOutPage: React.FC = () => {
  const { setMenuSelectedStepContext } = useRenaveContext();

  useEffect(() => {
    setMenuSelectedStepContext(RenaveMenuStepsEnum.CheckedOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ManageStockBasePage>
      <VehicleCheckedOutOrganism />
    </ManageStockBasePage>
  );
};
