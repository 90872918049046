import {
  HttpHelper,
  IKbbVehicle,
  IKbbVehicleModel,
  VehicleCategoriesEnum,
  SourcePartnerEnum,
} from "c4u-web-components";
import { useTranslation } from "react-i18next";
import { VehicleBrand, VehicleVersion, Years } from "../../../models";
import { kbbHttp, useRequest } from "../config";

export const useKbb = () => {
  const { get } = useRequest(kbbHttp, "Kbb");
  const { t } = useTranslation();

  const GetAllVehicleBrand = async (
    molicarCategory?: VehicleCategoriesEnum,
    sourcePartner?: SourcePartnerEnum
  ) => {
    const { data } = await get<VehicleBrand[]>(
      `GetAllVehicleBrand${HttpHelper.BuildQueryString({
        molicarCategory,
        sourcePartner,
      })}`
    );
    return data;
  };

  const GetAllVehicleModelByBrand = async (
    brandId: number,
    year: number | null = null,
    molicarCategory: VehicleCategoriesEnum,
    sourcePartner?: SourcePartnerEnum
  ) => {
    const { data } = await get<IKbbVehicleModel[]>(
      `GetAllVehicleModelByBrand/${brandId}` +
        `${HttpHelper.BuildQueryString({
          molicarCategory,
          year,
          sourcePartner,
        })}`
    );
    return data;
  };

  const GetAllVehicleVersion = async (
    modelId: number,
    year: number,
    sourcePartner?: SourcePartnerEnum
  ) => {
    const { data } = await get<VehicleVersion[]>(
      `GetAllVehicleVersion/${year}/${modelId}${HttpHelper.BuildQueryString({
        sourcePartner,
      })}`
    );
    return data;
  };

  const GetAllYears = async (sourcePartner?: SourcePartnerEnum) => {
    const { data } = await get<Years[]>(
      `GetAllYears${HttpHelper.BuildQueryString({ sourcePartner })}`
    );
    return data;
  };

  const GetVehicle = async (
    id: number,
    molicarID: string | null,
    year: number
  ) => {
    const { data } = await get(
      `GetVehicle/${id}${HttpHelper.BuildQueryString({
        molicarID,
        year,
      })}`
    );
    if (data && !data?.name) {
      const vehicle = data as IKbbVehicle;
      data.name = `${vehicle?.brandName} ${vehicle?.modelName} ${
        vehicle?.versionName
      } ${vehicle?.hp} ${t("Hp")} - ${vehicle?.year}`;
    }
    return data;
  };

  return {
    GetAllVehicleModelByBrand,
    GetAllVehicleVersion,
    GetAllYears,
    GetAllVehicleBrand,
    GetVehicle,
  };
};
