import { ButtonTertiary } from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";
import { paths } from "../../../../constants";
import { useRenave } from "../../../../hooks";
import { BalanceSummaryMolecule } from "../../../molecules";
import {
  BalanceAndCreditsWrapper,
  CreditButtonsWrapper,
} from "./balance-and-credits.organism.style";

interface IProps {
  goUpdate: boolean;
}

export const BalanceAndCreditsOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const isSmallScreen = useMediaQuery({ maxWidth: 767 });

  const { getDailyBalance } = useRenave();

  return (
    <BalanceAndCreditsWrapper>
      <BalanceSummaryMolecule
        goUpdate={props.goUpdate}
        getBalanceCallback={getDailyBalance}
        size={isSmallScreen ? "md" : "lg"}
      />

      <CreditButtonsWrapper>
        <ButtonTertiary
          onClick={() => push(paths.CreditAcquisitionPacksPage())}
          sizex="md"
          sizey="thin"
        >
          {t("BuyCredits")}
        </ButtonTertiary>

        <ButtonTertiary
          onClick={() => push(paths.creditStatement())}
          sizex="md"
          sizey="thin"
        >
          {t("CreditStatement")}
        </ButtonTertiary>
      </CreditButtonsWrapper>
    </BalanceAndCreditsWrapper>
  );
};
