import React, { useEffect } from "react";
import { ManageStockBasePage } from "../..";
import { useRenaveContext } from "../../../../hooks";
import { RenaveMenuStepsEnum } from "../../../../models";
import { CheckInVehicleOrganism } from "../../../organisms";

export const CheckInVehiclePage: React.FC = () => {
  const {
    menuCurrentStepContext,
    menuSelectedStepContext,
    setMenuSelectedStepContext,
  } = useRenaveContext();

  useEffect(() => {
    setMenuSelectedStepContext(RenaveMenuStepsEnum.CheckInVehicle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ManageStockBasePage>
      {menuSelectedStepContext === RenaveMenuStepsEnum.CheckInVehicle && (
        <CheckInVehicleOrganism
          viewMode={menuSelectedStepContext < menuCurrentStepContext}
        />
      )}
    </ManageStockBasePage>
  );
};
