import styled from "styled-components";

export const DownloadDocumentWrapper = styled.div`
  padding: 35px 25px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 8px 10px rgb(0 0 0 / 8%);
  border-radius: 10px;
  z-index: 1;
`;

const TextStyleBase = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;

export const DownloadDocumentTitle = styled(TextStyleBase)`
  color: ${(props) => props.theme.colors.DarkBlue};
`;

export const DownloadDocumentText = styled(TextStyleBase)`
  color: ${(props) => props.theme.colors.MediumGray};
`;

export const DownloadDocumentTextDate = styled(TextStyleBase)`
  color: ${(props) => props.theme.colors.DarkBlue};
`;

export const DownloadDocumentDescription = styled.div`
  display: flex;
  gap: 6px;
`;

export const DocumentDownloadInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
