import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "../../../constants";
import { HeaderTitleMolecule } from "../../molecules";
import { CreditAcquisitionPacksOrganism } from "../../organisms";

export const CreditAcquisitionPacksPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <HeaderTitleMolecule
        title={t("CreditAcquisition")}
        returnButtonActionOverride={() => history.push(paths.myStock())}
      />
      <CreditAcquisitionPacksOrganism />
    </>
  );
};
