import { CoxIcon } from "c4u-web-components";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { cardsRenaveInfo } from "../../../constants";
import { CardInfoAtom, OnScreenMessageAtom } from "../../atoms";
import {
  DescriptionInformation,
  HeaderInformation,
  TitleInformation,
  WrapperCardInfo,
  WrapperRenaveRegister,
} from "./renave-information.molecule.style";

interface IProps {
  showWaitContact?: boolean;
}

export const RenaveInformationMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <WrapperRenaveRegister>
      {!!props.showWaitContact && (
        <Row className="mt-4">
          <Col>
            <OnScreenMessageAtom
              text={
                <>
                  {t("WaitForContact")}&nbsp;&nbsp;
                  <CoxIcon name="phone" /> {t("Renave Phone")}
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <CoxIcon name="whatsapp" /> {t("Renave Whatsapp")}
                </>
              }
              type="success"
            />
          </Col>
        </Row>
      )}

      <Row className="mb-4  mt-4">
        <Col>
          <HeaderInformation>
            <TitleInformation>{t("WhatIsRenave")}</TitleInformation>
            <DescriptionInformation>
              {t("DevelopmentReason")}
            </DescriptionInformation>
          </HeaderInformation>
        </Col>
      </Row>

      <WrapperCardInfo>
        {cardsRenaveInfo.map((m, i) => (
          <Col md={12} lg={4} key={`card-item-${i}`}>
            <CardInfoAtom title={m.title} description={m.description} />
          </Col>
        ))}
      </WrapperCardInfo>
    </WrapperRenaveRegister>
  );
};
