import React, { useEffect } from "react";
import { ManageStockBasePage } from "../..";
import { useRenaveContext } from "../../../../hooks";
import { RenaveMenuStepsEnum } from "../../../../models";
import { VehicleCheckedInOrganism } from "../../../organisms";

export const VehicleCheckedInPage: React.FC = () => {
  const {
    menuCurrentStepContext,
    menuSelectedStepContext,
    setMenuSelectedStepContext,
  } = useRenaveContext();

  useEffect(() => {
    setMenuSelectedStepContext(RenaveMenuStepsEnum.CheckedIn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ManageStockBasePage>
      <VehicleCheckedInOrganism
        viewMode={menuSelectedStepContext < menuCurrentStepContext}
      />
    </ManageStockBasePage>
  );
};
