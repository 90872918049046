import styled from "styled-components";

export const StatusSummaryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const StatusSummaryItemWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 4px;
`;

export const StatusSummaryText = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  white-space: nowrap;

  color: ${(props) => props.theme.colors.Dark};
`;

const BaseCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: ${(props) => props.theme.fontFamily.Default};
  font-style: normal;
  font-weight: 700;
  font-size: 10px;

  min-width: 18px;
  height: 18px;
  border-radius: 7px;

  padding: 0 5px;

  color: #fff;
`;

export const BlueCircle = styled(BaseCircle)`
  background-color: #0065a2;
`;
export const GoldCircle = styled(BaseCircle)`
  background-color: #f7b01b;
`;
export const GreenCircle = styled(BaseCircle)`
  background-color: #5b9c4a;
`;
export const RedCircle = styled(BaseCircle)`
  background-color: #c4390a;
`;
