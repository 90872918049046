import { ButtonTertiary } from "c4u-web-components";
import React from "react";

import {
  CardDate,
  CardBodySubtitle,
  CardBodyTitle,
  CardBodyWrapperButtons,
  WrapperCardBodyRenave,
} from "./card-body.atom.style";

interface IProps {
  bodyTitle: string;
  bodyEntryDate: string;
  bodyOutDate: string;
  bodyTimeInStock: string;
  bodyButtons?: React.ReactNode[];
  onClickAccess?: () => void;
  hideAccessButton?: boolean;
  hideTimeInStock?: boolean;
  texts: {
    bodyTitle: string;
    checkInDateText?: string;
    checkOutDateText?: string;
    daysInStock?: string;
    buttonAcessTitle?: string;
  };
}

export const CardBodyAtom: React.FC<IProps> = (props) => {
  return (
    <WrapperCardBodyRenave>
      <div>
        <CardBodyTitle>
          {props.texts.bodyTitle}: {props.bodyTitle}
        </CardBodyTitle>
        <div className="d-flex flex-wrap">
          <CardDate className="mr-3">
            {props.texts.checkInDateText}: {props.bodyEntryDate}
          </CardDate>
          <CardDate>
            {props.texts.checkOutDateText}: {props.bodyOutDate}
          </CardDate>
        </div>
        {!props.hideTimeInStock && (
          <CardBodySubtitle>
            {props.texts.daysInStock}: {props.bodyTimeInStock}
          </CardBodySubtitle>
        )}
      </div>

      <CardBodyWrapperButtons>
        {!props.hideAccessButton && (
          <div className="pt-4">
            <ButtonTertiary
              sizex={"sm"}
              sizey={"thin"}
              onClick={props.onClickAccess}
              disabled={!props.onClickAccess}
            >
              {props.texts.buttonAcessTitle}
            </ButtonTertiary>
          </div>
        )}

        {props?.bodyButtons &&
          props.bodyButtons.map((m, i) => (
            <div key={`body-icon-${i}`}>{m}</div>
          ))}
      </CardBodyWrapperButtons>
    </WrapperCardBodyRenave>
  );
};
