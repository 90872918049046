import { Form } from "react-bootstrap";
import styled from "styled-components";

export const WrapperModalButtonOk = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  text-transform: uppercase;
`;

export const MainWrapperVehicleDataForm = styled.div`
  min-height: 500px;

  @media (max-width: 600px) {
    min-height: 600px;
  }
`;

export const StyledFormRow = styled(Form.Row)`
  input {
    height: unset;
  }

  .text-vehicle-brand {
    margin: 0 0 6px;
  }
  .text-vehicle-year {
    margin: 6px 0;
  }
  .text-vehicle-model {
    margin: 6px 0;
  }
  .text-vehicle-version {
    margin: 6px 0 12px;
  }
`;
