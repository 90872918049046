import { productsHttp, useRequest } from "../config";

import {
  GetPlateRegisterRequest,
  IGetPlateRegisterResponse,
  PostOutsideVehicleRegisterRequest,
} from "../../../models";

export const useProducts = () => {
  const { get, post } = useRequest(productsHttp, "Debts");

  const postOutsideVehicleRegister = async (
    request: PostOutsideVehicleRegisterRequest
  ) => {
    const { data } = await post<string>(`OutsideVehicleRegister`, request);
    return data;
  };

  const getByPlate = async (request: GetPlateRegisterRequest) => {
    const { data } = await get<IGetPlateRegisterResponse>(
      `GetByPlate/${request.plate}`
    );
    return data;
  };

  return {
    getByPlate,
    postOutsideVehicleRegister,
  };
};
