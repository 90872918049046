import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IPagedResponseBase } from "../../../models";
import { DoubleLeftArrow, SingleLeftArrow } from "../../assets";
import { PaginationButtonAtom } from "../../atoms";
import {
  MirroredImage,
  PageNumbers,
  PaginationControl,
} from "./pagination-control.molecule.styles";
import { Image } from "react-bootstrap";

interface IProps {
  paginationData: IPagedResponseBase;
  setPageNumber: (v: number) => void;
}
export const PaginationControlMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const {
    paginationData: { currentPage, totalPages },
    setPageNumber,
  } = props;

  const pagesDisplayed = useMemo(
    () =>
      totalPages <= 4
        ? [...new Array(totalPages)].map((_, i) => i + 1)
        : currentPage === 1
        ? [currentPage, currentPage + 1, "...", totalPages]
        : currentPage === totalPages
        ? [1, "...", currentPage - 1, currentPage]
        : [currentPage - 1, currentPage, "...", totalPages],
    [currentPage, totalPages]
  );

  return (
    <PaginationControl>
      <PaginationButtonAtom
        onClick={() => setPageNumber(1)}
        title={t("First page")}
        symbol={<Image src={DoubleLeftArrow} />}
        disabled={currentPage <= 1}
      />
      <PaginationButtonAtom
        onClick={() => setPageNumber(currentPage - 1)}
        title={t("Previous page")}
        symbol={<Image src={SingleLeftArrow} />}
        disabled={currentPage <= 1}
      />

      <PageNumbers>
        {pagesDisplayed.map((m, i) => (
          <PaginationButtonAtom
            key={`page-button-${i}`}
            onClick={typeof m === "number" ? () => setPageNumber(m) : undefined}
            symbol={m}
            selected={currentPage === m}
          />
        ))}
      </PageNumbers>

      <PaginationButtonAtom
        onClick={() => setPageNumber(currentPage + 1)}
        title={t("Next page")}
        symbol={<MirroredImage src={SingleLeftArrow} />}
        disabled={currentPage >= totalPages}
      />
      <PaginationButtonAtom
        onClick={() => setPageNumber(totalPages)}
        title={t("Last page")}
        symbol={<MirroredImage src={DoubleLeftArrow} />}
        disabled={currentPage >= totalPages}
      />
    </PaginationControl>
  );
};
