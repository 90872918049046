import {
  ButtonPrimary,
  CoxIcon,
  DateHelper,
  DownloadFileAtom,
  FilterAtom,
  theme,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { LocalStorageKeys } from "../../../../constants";
import { RenaveStatusHelper } from "../../../../helper";
import { useRenave, useSessionContext } from "../../../../hooks";
import {
  GetVehicleRequest,
  IGetSummaryResponse,
  IGetVehicleRequest,
  IGetVehicleResponse,
  IPagedResponseBase,
  RenaveStatusEnum,
} from "../../../../models";
import {
  FormikControlCalendarAtom,
  FormikControlRenaveAtom,
} from "../../../atoms";
import { StatusSummaryMolecule } from "../../../molecules";
import {
  CleanFiltersDiv,
  MenuIconsWrapper,
  MyStockHeaderWrapper,
  RotatableClickableDiv,
} from "./my-stock-header.organism.style";

interface IProps {
  setMyVehicles: (v: IGetVehicleResponse[] | undefined) => void;
  setPaginationData: (v: IPagedResponseBase | undefined) => void;
  pageNumber?: number;
  goUpdate: boolean;
  setGoUpdate: (v: boolean) => void;
}

export const MyStockHeaderOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { handleFormikException } = useSessionContext();
  const { getRenaveStock, getSummary, getStockReport } = useRenave();

  const {
    setMyVehicles: setMyVehiclesProps,
    setPaginationData: setPaginationDataProps,
    pageNumber: pageNumberProps,
  } = props;

  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [summaryData, setSummaryData] = useState<IGetSummaryResponse>();

  const validations = Yup.object<IGetVehicleRequest>({
    search: Yup.string().notRequired(),
    page: Yup.number().required(),
    pageSize: Yup.number().required(),
    startDate: Yup.string().nullable().notRequired(),
    finishDate: Yup.string().nullable().notRequired(),
    step: Yup.number<RenaveStatusEnum>().notRequired(),
  });

  const initialValues = useMemo<IGetVehicleRequest>(
    () => ({
      search: "",
      page: 1,
      pageSize: 10,
      startDate: "",
      finishDate: "",
      step: "",
    }),
    []
  );

  const formik = useFormik<IGetVehicleRequest>({
    initialValues: initialValues,
    onSubmit: async (values, { setErrors }) => {
      try {
        setMyVehiclesProps(undefined);
        const updateZeroKm =
          localStorage.getItem(LocalStorageKeys.UpdateZeroKm) === "true";
        const request = new GetVehicleRequest({
          ...values,
          updateZeroKm,
        });

        const { data, ...paginationData } = await getRenaveStock(request);
        setMyVehiclesProps(data);
        setPaginationDataProps(paginationData);

        if (updateZeroKm) {
          localStorage.setItem(LocalStorageKeys.UpdateZeroKm, "false");
        }
      } catch (e) {
        handleFormikException(e, setErrors);
        setMyVehiclesProps([]);
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const handleCleanFilters = useCallback(() => {
    formik.setValues(initialValues);
  }, [formik, initialValues]);

  useEffect(() => {
    if (!filterIsOpen) handleCleanFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterIsOpen]);

  useEffect(() => {
    if (pageNumberProps) formik.setFieldValue("page", pageNumberProps);
    formik.submitForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberProps]);

  const handleUpdateSummary = useCallback(() => {
    getSummary()
      .then((data) => setSummaryData(data))
      .catch((e) => console.log("Erro ao atualizar o resumo", e));
  }, [getSummary]);

  useEffect(() => {
    handleUpdateSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.goUpdate && !formik.isSubmitting) {
      setSummaryData(undefined);
      handleUpdateSummary();
      setMyVehiclesProps(undefined);
      setPaginationDataProps(undefined);
      formik.handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.goUpdate]);

  return (
    <>
      <MyStockHeaderWrapper>
        <Row>
          <Col lg={1} className="d-none d-lg-flex" />
          <Col lg={11}>
            <Form onSubmit={formik.handleSubmit}>
              <Form.Row>
                <FormikControlRenaveAtom
                  formik={formik}
                  type="search-submit"
                  property="search"
                  sm={6}
                  md={7}
                  lg={8}
                />
                <Col sm={6} md={4}>
                  <MenuIconsWrapper>
                    <FilterAtom
                      filterText={t("Filter")}
                      filterIsOpen={filterIsOpen}
                      setFilterIsOpen={setFilterIsOpen}
                      color={theme.colors.BlueNavy}
                    />
                    <DownloadFileAtom
                      getFileParams={undefined}
                      getFileCallback={getStockReport}
                      iconName="excel"
                      fileName={`Estoque Renave ${DateHelper.dateHourCustomFormat(
                        new Date(),
                        "dd-MM-yyyy"
                      )}.csv`}
                      htmlTitle={t("MyStockDownload")}
                    />
                    <RotatableClickableDiv
                      isLoading={formik.isSubmitting}
                      onClick={
                        formik.isSubmitting
                          ? undefined
                          : () => {
                              props.setGoUpdate(true);
                              localStorage.setItem(
                                LocalStorageKeys.UpdateZeroKm,
                                "true"
                              );
                            }
                      }
                      title={t("Update")}
                    >
                      <CoxIcon name="reload" />
                    </RotatableClickableDiv>
                  </MenuIconsWrapper>
                </Col>
              </Form.Row>

              {filterIsOpen && (
                <Form.Row className="d-flex align-items-end">
                  <FormikControlCalendarAtom
                    label={t("StartDate")}
                    property="startDate"
                    formik={formik}
                    xs={6}
                    lg={2}
                  />
                  <FormikControlCalendarAtom
                    label={t("FinishDate")}
                    property="finishDate"
                    formik={formik}
                    xs={6}
                    lg={2}
                  />
                  <FormikControlRenaveAtom
                    type={"select"}
                    data={RenaveStatusHelper.getStatusCombos(t)}
                    label={t("Status")}
                    property={"step"}
                    formik={formik}
                    placeholder={t("AllStatus")}
                    sm={6}
                    lg={3}
                  />

                  <Form.Group
                    as={Col}
                    xs={{ span: 4, offset: 1 }}
                    sm={{ span: 2, offset: 0 }}
                    md={2}
                    xl={1}
                    className="pb-2 text-center"
                  >
                    <ButtonPrimary
                      type="submit"
                      sizex="sm"
                      sizey="thin"
                      loading={formik.isSubmitting}
                    >
                      {t("OK")}
                    </ButtonPrimary>
                  </Form.Group>

                  <Form.Group as={Col} xs={6} sm={4} lg={3} className="pb-2">
                    <CleanFiltersDiv
                      onClick={
                        formik.isSubmitting ? undefined : handleCleanFilters
                      }
                    >
                      {t("CleanFilters")}
                    </CleanFiltersDiv>
                  </Form.Group>
                </Form.Row>
              )}

              <StatusSummaryMolecule summaryData={summaryData} />
            </Form>
          </Col>
        </Row>
      </MyStockHeaderWrapper>
    </>
  );
};
