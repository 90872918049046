import { ButtonSecondary, ButtonTertiary } from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "../../../constants";
import { useRenave, useRenaveContext, useSessionContext } from "../../../hooks";
import { CheckoutTypeEnum } from "../../../models";
import { SelectCheckoutButtonAtom } from "../../atoms";
import {
  CheckOutDealershipFormZeroKmMolecule,
  CheckOutCustomerFormZeroKmMolecule,
  ContentCardMolecule,
} from "../../molecules";
import {
  ButtonWrapper,
  ManageVehicleContentWrapper,
} from "../shared-style/organisms.shared-style";

interface IProps {
  viewMode?: boolean;
}

export const CheckOutZeroKmOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { zeroKmVehicleCheckoutContext } = useRenaveContext();
  const { showGenericWarningModal } = useSessionContext();

  const { getDailyBalance, postCheckOutZeroKm } = useRenave();

  const [selectedButton, setSelectedButton] = useState<CheckoutTypeEnum | null>(
    null
  );
  const [goSubmit, setGoSubmit] = useState<boolean>(false);
  const [goClearForm, setGoClearForm] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const validateCreditsModal = useCallback(() => {
    showGenericWarningModal(t("NoCredtisValidationMsg"), () => {
      history.replace(paths.CreditAcquisitionPacksPage());
    });
  }, [showGenericWarningModal, history, t]);

  const hasCredits = useCallback(async () => {
    const response = await getDailyBalance();
    return response?.dailyBalance > 0;
  }, [getDailyBalance]);

  const validateCredits = useCallback(async () => {
    try {
      const credits = await hasCredits();
      if (!credits) {
        validateCreditsModal();
        return false;
      }
      return true;
    } catch (error) {
      validateCreditsModal();
      return false;
    }
  }, [validateCreditsModal, hasCredits]);

  useEffect(() => {
    validateCredits();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (goSubmit) setGoSubmit(false);
  }, [goSubmit]);

  useEffect(() => {
    if (goClearForm) setGoClearForm(false);
  }, [goClearForm]);

  useEffect(() => {
    if (zeroKmVehicleCheckoutContext?.checkOutZeroKmType !== undefined)
      setSelectedButton(zeroKmVehicleCheckoutContext.checkOutZeroKmType);
  }, [zeroKmVehicleCheckoutContext?.checkOutZeroKmType]);

  return (
    <>
      <SelectCheckoutButtonAtom
        selectedButton={selectedButton}
        setSelectedButton={setSelectedButton}
        setGoClearForm={setGoClearForm}
        viewMode={props.viewMode}
      />

      {selectedButton === CheckoutTypeEnum.TransferAuthorization && (
        <ManageVehicleContentWrapper>
          <ContentCardMolecule title={t("TransferToDealership")}>
            <CheckOutDealershipFormZeroKmMolecule
              goSubmit={goSubmit}
              goClearForm={goClearForm}
              setIsSubmitting={setIsSubmitting}
              creditsToSubmit={validateCredits}
              saveCheckout={postCheckOutZeroKm}
              viewMode={props.viewMode}
            />
          </ContentCardMolecule>
        </ManageVehicleContentWrapper>
      )}

      {selectedButton === CheckoutTypeEnum.Exit && (
        <ManageVehicleContentWrapper>
          <ContentCardMolecule title={t("TransferToCustomer")}>
            <CheckOutCustomerFormZeroKmMolecule
              goSubmit={goSubmit}
              goClearForm={goClearForm}
              setIsSubmitting={setIsSubmitting}
              creditsToSubmit={validateCredits}
              saveCheckout={postCheckOutZeroKm}
              viewMode={props.viewMode}
            />
          </ContentCardMolecule>
        </ManageVehicleContentWrapper>
      )}

      {selectedButton !== null && (
        <ButtonWrapper>
          <ButtonSecondary
            sizex="md"
            onClick={() => setGoClearForm(true)}
            disabled={!!props.viewMode || isSubmitting}
          >
            {t("Clean")}
          </ButtonSecondary>

          <ButtonTertiary
            sizex="md"
            loading={isSubmitting}
            onClick={() => setGoSubmit(true)}
            disabled={!!props.viewMode}
          >
            {selectedButton === CheckoutTypeEnum.TransferAuthorization
              ? t("Authorize")
              : t("Request Checkout")}
          </ButtonTertiary>
        </ButtonWrapper>
      )}
    </>
  );
};
