import { ButtonTertiary } from "c4u-web-components";
import styled from "styled-components";

const HeaderTitleWrapper = styled.div`
  margin: 28px 0 21px;

  .center-align-vertical {
    button {
      margin: unset;
    }
    display: flex;
    align-items: center;
  }
`;

const TitleDiv = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: 30px;
  text-align: left;
  flex-grow: 1;
  color: ${(props) => props.theme.colors.BlueNavy};
`;

const AllCapsButtonTertiary = styled(ButtonTertiary)`
  text-transform: uppercase;
`;

export { AllCapsButtonTertiary, HeaderTitleWrapper, TitleDiv };
