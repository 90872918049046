import { TypesHelper } from "c4u-web-components";
import { TypeDocumentEnum, TypeEntryEnum, TypeSignatureEnum } from "../..";

export interface IPurchaseData {
  typeDocumentCurrentOwner: TypeDocumentEnum;
  documentCurrentOwner: string;
  emailCurrentOwner: string;
  valuePurchase?: string;
  datePurchase?: Date;
}

export interface IVehicleDataFormMolecule extends IPurchaseData {
  id?: number;
  securityCodeCrv: string;
  typeEntryEnum?: TypeEntryEnum;
  odometerMileage: string;
  typeSignature?: TypeSignatureEnum;
  dealershipEmail: string;
  measurementOdometerDate?: Date;
  measurementOdometerHour: string;
  stockEntryDate?: Date;
  manufactureYear?: number;
  stockEntryHour: string;
}

export interface IVehicleEntryRequest extends IVehicleDataFormMolecule {
  kbbId: string;
  molicarID: string;

  plate: string;
  renavam: string;
  numberCrv: string;
  typeCrv: number;

  brand?: string;
  model?: string;
  version?: string;
  modelYear?: number;

  isEdit?: boolean | null;
}

export class PurchaseData {
  typeDocumentCurrentOwner: number | null;
  documentCurrentOwner: string | null;
  emailCurrentOwner: string | null;
  valuePurchase: string | null;
  datePurchase: Date | null;

  constructor({
    typeDocumentCurrentOwner,
    documentCurrentOwner,
    emailCurrentOwner,
    valuePurchase,
    datePurchase,
  }: IPurchaseData) {
    this.typeDocumentCurrentOwner = TypesHelper.toNumberNull(
      typeDocumentCurrentOwner
    );
    this.documentCurrentOwner =
      TypesHelper.toStringOnlyNumbersNull(documentCurrentOwner);
    this.emailCurrentOwner = TypesHelper.toStringNull(emailCurrentOwner);
    this.valuePurchase = TypesHelper.toStringNull(valuePurchase);
    this.datePurchase = TypesHelper.toDateNull(datePurchase?.toISOString());
  }
}

export class VehicleEntryRequest {
  id: number | null;
  kbbId: number | null;
  molicarID: string | null;
  securityCodeCrv: string | null;
  manufactureYear: number | null;
  odometerMileage: string | null;
  measurementOdometerDate: string | null;
  measurementOdometerHour: string | null;
  dealershipEmail: string | null;
  typeSignature: number | null;
  typeEntryEnum: number | null;
  brand: string | null;
  model: string | null;
  version: string | null;
  modelYear: number | null;
  plate: string | null;
  renavam: string | null;
  numberCrv: string | null;
  typeCrv: number | null;
  purchaseData: PurchaseData | null;
  stockEntryDate: string | null;
  stockEntryHour?: string | null;
  isEdit?: boolean | null;

  constructor({
    id,
    kbbId,
    molicarID,
    securityCodeCrv,
    manufactureYear,
    odometerMileage,
    measurementOdometerDate,
    measurementOdometerHour,
    dealershipEmail,
    typeSignature,
    typeEntryEnum,
    brand,
    model,
    version,
    modelYear,
    plate,
    renavam,
    numberCrv,
    typeCrv,
    stockEntryDate,
    stockEntryHour,
    typeDocumentCurrentOwner,
    documentCurrentOwner,
    emailCurrentOwner,
    valuePurchase,
    datePurchase,
    isEdit,
  }: IVehicleEntryRequest) {
    this.id = TypesHelper.toNumberNull(id);
    this.kbbId = TypesHelper.toNumberNull(kbbId);
    this.molicarID = TypesHelper.toStringNull(molicarID);
    this.securityCodeCrv = TypesHelper.toStringNull(securityCodeCrv);
    this.manufactureYear = TypesHelper.toNumberNull(manufactureYear);
    this.odometerMileage = TypesHelper.toStringNull(odometerMileage);

    const timeOdometer = measurementOdometerHour?.split(":");
    measurementOdometerDate?.setUTCHours(Number(timeOdometer[0]));
    measurementOdometerDate?.setUTCMinutes(Number(timeOdometer[1]));
    this.measurementOdometerDate = TypesHelper.toStringNull(
      measurementOdometerDate?.toISOString()
    );

    this.measurementOdometerHour = TypesHelper.toStringNull(
      measurementOdometerHour
    );
    this.dealershipEmail = TypesHelper.toStringNull(dealershipEmail);
    this.typeSignature = TypesHelper.toNumberNull(typeSignature);
    this.typeEntryEnum = TypesHelper.toNumberNull(typeEntryEnum);
    this.brand = TypesHelper.toStringNull(brand);
    this.model = TypesHelper.toStringNull(model);
    this.version = TypesHelper.toStringNull(version);
    this.modelYear = TypesHelper.toNumberNull(modelYear);
    this.plate = TypesHelper.toStringNull(plate);
    this.renavam = TypesHelper.toStringNull(renavam);
    this.numberCrv = TypesHelper.toStringNull(numberCrv);
    this.typeCrv = TypesHelper.toNumberNull(typeCrv);
    this.isEdit = TypesHelper.toBooleanNull(isEdit);

    const timeEntryDate = stockEntryHour?.split(":");
    stockEntryDate?.setUTCHours(Number(timeEntryDate[0]));
    stockEntryDate?.setUTCMinutes(Number(timeEntryDate[1]));
    this.stockEntryDate = TypesHelper.toStringNull(
      stockEntryDate?.toISOString()
    );

    this.purchaseData = new PurchaseData({
      typeDocumentCurrentOwner,
      documentCurrentOwner,
      emailCurrentOwner,
      valuePurchase,
      datePurchase,
    });
  }
}
