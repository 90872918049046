import {
  ButtonPrimary,
  ButtonSecondary,
  FormikControlAtom,
  MsgValidation,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { EnumHelper } from "../../../../helper";
import { useSessionContext } from "../../../../hooks";
import {
  ConflictRequestError,
  IPostRejectZeroKmRequest,
  PostRejectZeroKmRequest,
  RejectZeroKmReasonsEnum,
} from "../../../../models";
import { FeedbackModal } from "../../feedback-modal/feedback-modal.molecule";
import {
  RejectFormButtonsGroup,
  RejectModalFormRow,
} from "./reject-entry-modal.molecule.style";

interface IProps {
  id: number | undefined;
  setId: (v: number | undefined) => void;
  rejectEntryCallback: (r: PostRejectZeroKmRequest) => Promise<void>;
  onRejectSuccessful: () => void;
}

export const RejectEntryModalMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { handleFormikException, showGenericErrorModal } = useSessionContext();

  const msgRequired = t(MsgValidation.RequiredField);

  const validations = Yup.object<IPostRejectZeroKmRequest>({
    id: Yup.number().required(msgRequired),
    rejectionReason: Yup.number<RejectZeroKmReasonsEnum>().required(msgRequired),
  });

  const formik = useFormik<IPostRejectZeroKmRequest>({
    initialValues: { id: props.id } as IPostRejectZeroKmRequest,
    onSubmit: async (values, { setErrors }) => {
      try {
        await props.rejectEntryCallback(new PostRejectZeroKmRequest(values));
        props.onRejectSuccessful();
      } catch (e: any) {
        if (e instanceof ConflictRequestError) {
          handleFormikException(e, setErrors);
        } else {
          showGenericErrorModal(t("GenericErrorMsg"), onHide, t("Error"));
        }
      } finally {
        onHide();
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const onHide = () => {
    props.setId(undefined);
    formik.resetForm();
  };

  useEffect(() => {
    if (props.id) formik.setFieldValue("id", props.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  return (
    <FeedbackModal
      show={props.id !== undefined}
      message={t("Confirm rejection message")}
      titleHeader={t("Reject 0km vehicle entry")}
      onHide={onHide}
      typeIcon="none"
      size="md"
      hideFooter
      reducePaddingTop
    >
      <Form onSubmit={formik.handleSubmit}>
        <RejectModalFormRow>
          <FormikControlAtom
            translate={t}
            type="select"
            data={EnumHelper.getComboFromEnum(RejectZeroKmReasonsEnum, t)}
            formik={formik}
            property="rejectionReason"
            label={t("Rejection reason")}
          />
        </RejectModalFormRow>
        <Form.Row>
          <RejectFormButtonsGroup>
            <ButtonSecondary onClick={onHide}>{t("Cancel")}</ButtonSecondary>
            <ButtonPrimary type="submit" loading={formik.isSubmitting}>
              {t("Confirm")}
            </ButtonPrimary>
          </RejectFormButtonsGroup>
        </Form.Row>
      </Form>
    </FeedbackModal>
  );
};
