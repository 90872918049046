import styled from "styled-components";

export const DownloadButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;

  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;

  color: ${(props) => props.theme.colors.BlueNavy};

  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  opacity: ${(props) => (props.onClick ? 1 : 0.4)};

  &.show-inline {
    flex-direction: row-reverse;
    gap: 11px;
    text-decoration-line: underline;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
  }
`;

export const DownloadTitleDiv = styled.div`
  white-space: nowrap;
`;
