import { MsgValidation, RegexValidation, YupHelper } from "c4u-web-components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { IGetRestrictionRequest, TypeCrvEnum } from "../../../models";

export const VehicleAptnessValidationSchema = () => {
  const { t } = useTranslation();

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isValidByRegex",
    YupHelper.isValidByRegex
  );

  const msgRequired = t(MsgValidation.RequiredField);

  const validations = Yup.object<IGetRestrictionRequest>({
    plate: Yup.string().required(msgRequired),
    renavam: Yup.string()
      .required(msgRequired)
      .isValidByRegex(RegexValidation.Renavam, "Renavam inválido"),
    numberCrv: Yup.string()
      .required(msgRequired)
      .isValidByRegex(RegexValidation.Crv, "CRV inválido"),
    typeCrv: Yup.number<TypeCrvEnum>().required(msgRequired),
  });

  return validations;
};
