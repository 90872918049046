import { CreditPackCardAtom, NumberHelper } from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "../../../constants";
import { ICreditPacks } from "../../../models";
import {
  CreditPacksSectionTitle,
  CreditPacksSectionWrapper,
  CreditPacksWrapper,
} from "./credit-packs.molecule.style";

interface IProps {
  title: string;
  packs: ICreditPacks[];
}

export const CreditPacksMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <CreditPacksSectionWrapper>
      <CreditPacksSectionTitle>{props.title}</CreditPacksSectionTitle>
      <CreditPacksWrapper>
        {props.packs.map((m, i) => (
          <CreditPackCardAtom
            key={`pack-${props.title}-${i}`}
            title={m.description}
            valueLabel={t("Value") + ":"}
            valueText={t("{{value}} ValuePlan", {
              value: NumberHelper.toFormatString(m.value, 2),
            })}
            buttonText={t("Buy")}
            onChoose={() =>
              history.push(paths.CreditAcquisitionPaymentPage(m.id))
            }
          />
        ))}
      </CreditPacksWrapper>
    </CreditPacksSectionWrapper>
  );
};
