import { ButtonSecondary, ButtonTertiary } from "c4u-web-components";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../constants";
import { useRenave, useRenaveContext, useSessionContext } from "../../../hooks";
import { RenaveMenuStepsZeroKmEnum } from "../../../models";
import {
  ContentCardMolecule,
  ReturnToMakerModalMolecule,
  ZeroKmVehicleDataFormMolecule,
} from "../../molecules";
import { ButtonWrapper } from "../shared-style/organisms.shared-style";

interface IProps {
  viewMode?: boolean;
}

export const ZeroKmVehicleCheckedInOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { postReturnZeroKm } = useRenave();
  const history = useHistory();
  const {
    zeroKmVehicleEntryContext,
    setMenuCurrentStepZeroKmContext,
    vehicleCategoryContext,
    changeVehicleCategoryContext,
  } = useRenaveContext();
  const { showGenericSuccessModal } = useSessionContext();
  const params = useParams<{ id: string }>();

  const [returnId, setReturnId] = useState<number>();
  return (
    <>
      <ContentCardMolecule title={t("ZeroKmVehicle")}>
        <ZeroKmVehicleDataFormMolecule
          initialValues={zeroKmVehicleEntryContext}
          disableAll
          vehicleCategory={vehicleCategoryContext}
          setVehicleCategory={changeVehicleCategoryContext}
        />
      </ContentCardMolecule>

      <ButtonWrapper>
        <ButtonSecondary
          className="line-spacing-115"
          sizex="md"
          onClick={() => setReturnId(parseInt(params.id))}
          disabled={props.viewMode}
        >
          {t("Return to maker")}
        </ButtonSecondary>

        <ButtonTertiary
          sizex="md"
          onClick={() => {
            setMenuCurrentStepZeroKmContext(
              RenaveMenuStepsZeroKmEnum.CheckOutVehicleZeroKm
            );
            history.push(paths.checkOutZeroKm(params.id));
          }}
          disabled={props.viewMode}
        >
          {t("Checkout")}
        </ButtonTertiary>
      </ButtonWrapper>

      <ReturnToMakerModalMolecule
        id={returnId}
        setId={setReturnId}
        returnEntryCallback={postReturnZeroKm}
        onReturnSuccessful={() =>
          showGenericSuccessModal(
            null,
            () => history.push(paths.myStock()),
            t("Vehicle returned to maker")
          )
        }
        entryDanfeNumber={zeroKmVehicleEntryContext?.invoiceKey ?? ""}
      />
    </>
  );
};
