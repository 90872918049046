import React from "react";
import { useTranslation } from "react-i18next";
import { HeaderTitleMolecule } from "../../molecules";
import { RegisterDealershipOrganism } from "../../organisms";

export const RegisterDealershipPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeaderTitleMolecule title={t("Renave")} hideReturnButton />
      <RegisterDealershipOrganism />
    </>
  );
};
