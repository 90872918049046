import React from "react";
import { useTranslation } from "react-i18next";
import {
  HeaderTitleMolecule,
  RenaveInformationMolecule,
} from "../../molecules";

export const StaticRenaveInfoMasterPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <HeaderTitleMolecule title={t("Renave")} hideReturnButton />
      <hr />
      <RenaveInformationMolecule showWaitContact/>
    </>
  );
};
