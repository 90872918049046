import styled from "styled-components";

const WrapperCard = styled.div``;

const ContentCardRegister = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  width: 100%;
  min-height: 272px;

  padding: 10px;

  border-radius: 10px;
  border-top: 11px solid #00aeef;

  background-color: ${(props) => props.theme.colors.DarkGrayOpacity15};
`;

const TitleCardRegister = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;

  letter-spacing: -0.15px;

  color: ${(props) => props.theme.colors.BlueNavy};

  text-align: center;
`;

const DescriptioCardRegister = styled.div`
  li {
    font-family: ${(props) => props.theme.fontFamily.Montserrat};
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 18px;

    color: ${(props) => props.theme.colors.BlueNavy};
  }

  ul {
    list-style: initial;
  }
`;

export {
  WrapperCard,
  ContentCardRegister,
  TitleCardRegister,
  DescriptioCardRegister,
};
