import { TypesHelper } from "c4u-web-components";
import { CheckoutTypeEnum, TypeDocumentEnum } from "../..";

export interface IPostDealershipCheckOutZeroKmRequest {
  id: number;
  saleValue?: number;
  buyerDocument?: string;
}

export class PostDealershipCheckOutZeroKmRequest {
  id: number | null;
  buyerDocumentType: TypeDocumentEnum | null;
  buyerDocument: string | null;
  saleValue: number | null;
  checkOutZeroKmType: CheckoutTypeEnum | null;

  constructor({
    id,
    buyerDocument,
    saleValue,
  }: IPostDealershipCheckOutZeroKmRequest) {
    this.id = TypesHelper.toNumberNull(id);
    this.buyerDocumentType = TypeDocumentEnum.Cnpj;
    this.buyerDocument = TypesHelper.toStringOnlyNumbersNull(buyerDocument);
    this.saleValue = TypesHelper.toNumberNull(saleValue);
    this.checkOutZeroKmType = CheckoutTypeEnum.TransferAuthorization;
  }
}
