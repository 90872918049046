import styled from "styled-components";

export const CancelRejectionFormButtonsGroup = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  margin: 36px 0 0;
`;
