import { TypesHelper, VehicleDebtOriginEnum } from "c4u-web-components";

export interface IOutsideVehicleRegisterRequest {
  passwordless?: string;
  name?: string;
  document?: string;
  plate: string;
  renavam: string;
  detranUf: string;
  origin: VehicleDebtOriginEnum;
  originId?: number;
}

export class PostOutsideVehicleRegisterRequest {
  passwordless: string | null;
  name: string | null;
  document: string | null;
  plate: string | null;
  renavam: string | null;
  detranUf: string | null;
  origin: number | null;
  originId: VehicleDebtOriginEnum | null;

  constructor({
    passwordless,
    name,
    document,
    plate,
    renavam,
    detranUf,
    origin,
    originId,
  }: IOutsideVehicleRegisterRequest) {
    this.passwordless = TypesHelper.toStringNull(passwordless);
    this.name = TypesHelper.toStringNull(name);
    this.document = TypesHelper.toStringNull(document);
    this.plate = TypesHelper.toStringNull(plate);
    this.renavam = TypesHelper.toStringNull(renavam);
    this.detranUf = TypesHelper.toStringNull(detranUf);
    this.origin = TypesHelper.toNumberNull(origin);
    this.originId = TypesHelper.toNumberNotZeroNull(originId);
  }
}
