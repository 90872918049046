import styled from "styled-components";
import { Form } from "react-bootstrap";

export const DatePickerFormGroupWrapper = styled(Form.Group)`
  margin-bottom: 1rem;

  .react-datepicker__day-name {
    width: 2.2rem;
  }

  .react-datepicker__day {
    width: 2.2rem;
  }
`;
