import { TypesHelper } from "c4u-web-components";

export interface ICheckInZeroKmModel {
  id: number;
  kbbId: number;
  molicarID?: string;
  vin: string;

  brand?: string;
  model?: string;
  version?: string;
  modelYear?: number;

  manufactureYear?: number;
  valuePurchase?: string;
  entryType?: string;
  stockEntryDate?: Date;
  odometerMileage?: string;
  measurementOdometerDate?: Date;
  measurementOdometerHour: string;
  invoiceKey: string;
}

export class PostCheckInZeroKmRequest {
  id: number | null;
  kbbId: number | null;
  molicarID?: string | null;

  brand: string | null;
  model: string | null;
  version: string | null;
  modelYear: number | null;

  manufactureYear: number | null;
  valuePurchase: number | null;
  stockEntryDate: string | null;
  odometerMileage: string | null;
  measurementOdometerDate: string | null;
  invoiceKey: string | null;

  constructor({
    manufactureYear,
    valuePurchase,
    stockEntryDate,
    odometerMileage,
    measurementOdometerDate,
    measurementOdometerHour,
    invoiceKey,
    id,
    kbbId,
    molicarID,
    brand,
    model,
    version,
    modelYear,
  }: ICheckInZeroKmModel) {
    this.id = TypesHelper.toNumberNull(id);
    this.kbbId = TypesHelper.toNumberNull(kbbId);
    this.molicarID = TypesHelper.toStringNull(molicarID);
    this.brand = TypesHelper.toStringNull(brand);
    this.model = TypesHelper.toStringNull(model);
    this.version = TypesHelper.toStringNull(version);
    this.modelYear = TypesHelper.toNumberNull(modelYear);
    this.manufactureYear = TypesHelper.toNumberNull(manufactureYear);
    this.valuePurchase = TypesHelper.toNumberNull(valuePurchase);
    stockEntryDate?.setUTCHours(12);
    stockEntryDate?.setUTCMinutes(0);
    this.stockEntryDate = TypesHelper.toStringNull(
      stockEntryDate?.toISOString()
    );
    this.odometerMileage = TypesHelper.toStringNull(odometerMileage);
    const timeOdometer = measurementOdometerHour?.split(":");
    measurementOdometerDate?.setUTCHours(Number(timeOdometer[0]));
    measurementOdometerDate?.setUTCMinutes(Number(timeOdometer[1]));
    this.measurementOdometerDate = TypesHelper.toStringNull(
      measurementOdometerDate?.toISOString()
    );
    this.invoiceKey = TypesHelper.toStringNull(invoiceKey);
  }
}
