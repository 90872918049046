import { TypesHelper } from "c4u-web-components";

interface IVehicleDanfeRequest {
  id?: number;
  invoiceKey: string;
}

export interface IVehicleDanfeResponse {
  name?: string;
  invoiceDate?: string;
}

export class VehicleDanfeRequest {
  id: number | null;
  invoiceKey: string | null;

  constructor({ id, invoiceKey }: IVehicleDanfeRequest) {
    this.id = TypesHelper.toNumberNull(id);
    this.invoiceKey = TypesHelper.toStringNull(invoiceKey);
  }
}

export interface IVehicleDanfeForm
  extends IVehicleDanfeRequest,
    IVehicleDanfeResponse {}
