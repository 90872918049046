import { ButtonTertiary, CoxIcon, DateHelper } from "c4u-web-components";
import React, { useCallback, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FileHelper } from "../../../helper";
import { useSessionContext } from "../../../hooks";
import { IGetDocumentResponse } from "../../../models";
import {
  DocumentDownloadInfoWrapper,
  DownloadDocumentDescription,
  DownloadDocumentText,
  DownloadDocumentTextDate,
  DownloadDocumentTitle,
  DownloadDocumentWrapper,
} from "./card-download-document.molecule.style";

interface IProps {
  iconName: string;
  title: string;
  text?: string;
  buttonText?: string;
  onClickGetDocument: () => Promise<IGetDocumentResponse>;
  disabled?: boolean;
  errorMsgModal?: string;
}

export const CardDownloadDocumentMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { showGenericErrorModal } = useSessionContext();

  const {
    iconName,
    title,
    text,
    buttonText,
    onClickGetDocument,
    disabled,
    errorMsgModal,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const createdAt = DateHelper.dateHourCustomFormat(
    new Date().toISOString(),
    "dd/MM/yyyy 'às' HH'h'mm"
  );

  const handleDownload = useCallback(async () => {
    setIsLoading(true);
    FileHelper.downloadBase64PdfFile(
      onClickGetDocument,
      window,
      () =>
        showGenericErrorModal(
          errorMsgModal ? t(errorMsgModal) : t("GenericErrorMsg"),
          null,
          t("Error")
        ),
      setIsLoading
    );
  }, [onClickGetDocument, showGenericErrorModal, t, errorMsgModal]);

  return (
    <>
      <DownloadDocumentWrapper>
        <Row>
          <Col xs={1} className="text-center">
            <CoxIcon name={iconName} width={24} height={24} />
          </Col>

          <Col xs={10}>
            <DocumentDownloadInfoWrapper>
              <DownloadDocumentTitle>{title}</DownloadDocumentTitle>

              <DownloadDocumentDescription>
                <DownloadDocumentText>
                  {text ?? t("DocumentGeneratedIn")}
                </DownloadDocumentText>
                <DownloadDocumentTextDate>{createdAt}</DownloadDocumentTextDate>
              </DownloadDocumentDescription>

              <div className="mt-1">
                <ButtonTertiary
                  sizex="md"
                  loading={isLoading}
                  onClick={handleDownload}
                  disabled={!!disabled}
                >
                  {buttonText ?? t("Print")}
                </ButtonTertiary>
              </div>
            </DocumentDownloadInfoWrapper>
          </Col>
        </Row>
      </DownloadDocumentWrapper>
    </>
  );
};
