import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "../../../constants";
import { HeaderTitleMolecule } from "../../molecules";
import { CreditAcquisitionPaymentOrganism } from "../../organisms";

export const CreditAcquisitionPaymentPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <HeaderTitleMolecule
        title={t("CreditAcquisition")}
        returnButtonActionOverride={() =>
          history.push(paths.CreditAcquisitionPacksPage())
        }
      />
      <CreditAcquisitionPaymentOrganism />
    </>
  );
};
