import { ButtonPrimary } from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../../constants";
import { useRenave, useSessionContext } from "../../../../hooks";
import {
  ICreditAcquisitionForm,
  IFormikSetErrors,
  PostPacksRequest,
} from "../../../../models";
import { CreditAcquisitionFormMolecule } from "../../../molecules";
import { WrapperCreditAcquisition } from "./credit-acquisition-payment.organism.style";

export const CreditAcquisitionPaymentOrganism: React.FC = () => {
  const { t } = useTranslation();
  const { postPacks } = useRenave();
  const {
    handleFormikException,
    showGenericSuccessModal,
    showGenericErrorModal,
  } = useSessionContext();
  const params = useParams<{ id: string }>();

  const [goSubmit, setGoSubmit] = useState(false);
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmitCreditPayment = useCallback(
    async (
      values: ICreditAcquisitionForm,
      { setErrors }: IFormikSetErrors<ICreditAcquisitionForm>
    ) => {
      try {
        const data = await postPacks(
          new PostPacksRequest({
            packId: Number(params.id),
            ...values,
          })
        );
        data.result
          ? showGenericSuccessModal(
              " ",
              () => history.push(paths.home()),
              t("PaymentAccept")
            )
          : showGenericErrorModal(" ", null, t("PaymentFailed"));
      } catch (e) {
        handleFormikException(e, setErrors);
      }
    },
    [
      handleFormikException,
      history,
      params.id,
      postPacks,
      showGenericErrorModal,
      showGenericSuccessModal,
      t,
    ]
  );

  useEffect(() => {
    if (goSubmit) setGoSubmit(false);
  }, [goSubmit]);

  return (
    <WrapperCreditAcquisition>
      <Row>
        <Col
          xs={{ offset: 1, span: 10 }}
          sm={{ offset: 2, span: 10 }}
          md={{ offset: 1, span: 12 }}
          lg={{ offset: 2, span: 10 }}
        >
          <CreditAcquisitionFormMolecule
            goSubmit={goSubmit}
            onSubmit={handleSubmitCreditPayment}
            setIsSubmitting={setIsSubmitting}
          />
        </Col>
      </Row>
      <Row>
        <Col
          className="d-flex mt-5"
          xs={{ offset: 6, span: 6 }}
          sm={{ offset: 7, span: 6 }}
          md={{ offset: 4, span: 6 }}
        >
          <ButtonPrimary
            sizex="md"
            loading={isSubmitting}
            onClick={() => {
              setGoSubmit(true);
            }}
          >
            {t("PayNow")}
          </ButtonPrimary>
        </Col>
      </Row>
    </WrapperCreditAcquisition>
  );
};
