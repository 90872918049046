import { TypesHelper } from "c4u-web-components";

interface IPostExitCancellationRequest {
  id: number;
}

export class PostExitCancellationRequest {
  id: number | null;

  constructor({ id }: IPostExitCancellationRequest) {
    this.id = TypesHelper.toNumberNull(id);
  }
}
