import { NumberHelper } from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { ISizeProp } from "../../../models";
import {
  BalanceContent,
  BalanceLabel,
  BalanceCardWrapper,
} from "./balance-summary.atom.style";

interface IProps {
  label: string;
  value?: number;
  size?: ISizeProp;
}

export const BalanceSummaryAtom: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <BalanceCardWrapper>
      <BalanceLabel size={props.size}>{props.label}</BalanceLabel>
      <BalanceContent size={props.size}>
        {t("Credit {value}", {
          value: NumberHelper.toFormatString(props.value),
        })}
      </BalanceContent>
    </BalanceCardWrapper>
  );
};
