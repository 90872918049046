export class VehicleHelper {
  static formatName = (
    brand: string,
    model: string,
    year: number,
    version: string
  ) => {
    return `${brand} ${model} ${year} - ${version}`;
  };
}
