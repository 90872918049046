import { DateHelper } from "c4u-web-components";
import React, { useCallback, useMemo } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { RenaveStatusHelper } from "../../../helper";
import { IGetVehicleResponse, RenaveStatusEnum } from "../../../models";
import { LogoControlStock } from "../../assets";
import { CardBodyAtom } from "../../atoms/card-body/card-body.atom";
import { CardHeaderAtom } from "../../atoms/card-header/card-header.atom";
import { RenaveCardDiv, RenaveCardWrapper } from "./renave-card.molecule.style";

interface TempProps {
  cardData: IGetVehicleResponse;
  getBodyButtons: (
    id: number,
    status: RenaveStatusEnum,
    vin?: string
  ) => React.ReactNode[] | undefined;
  getOnClickAccess: (
    id: number,
    status: RenaveStatusEnum,
    isZeroKm: boolean
  ) => (() => void) | undefined;
}

export const RenaveCardMolecule: React.FC<TempProps> = (props) => {
  const { t } = useTranslation();

  const image = useMemo(
    () => <Image width={55} height={24} src={LogoControlStock} />,
    []
  );

  const calculateTimeDiffInDays = useCallback(
    (start?: string, end?: string): string => {
      const days = DateHelper.getTimeDiffDays(start, end);

      if (!days) return "-";
      if (days === 1) return t("1 day");
      return t("{n} days", { n: days.toString() });
    },
    [t]
  );

  const formatDate = useCallback(
    (date: string | undefined) =>
      date ? DateHelper.dateHourCustomFormat(date, "dd/MM/yyyy - HH:mm") : "-",
    []
  );

  const fillColor = useMemo(
    () => RenaveStatusHelper.getStatusColor(props.cardData.step),
    [props.cardData.step]
  );

  const isPendingZeroKm = useMemo(
    () => props.cardData.step === RenaveStatusEnum.PendingZeroKm,
    [props.cardData.step]
  );
  const isZeroKm = useMemo(
    () =>
      [
        RenaveStatusEnum.PendingZeroKm,
        RenaveStatusEnum.RejectedZeroKm,
        RenaveStatusEnum.ReturnedZeroKm,
        RenaveStatusEnum.CheckedInZeroKm,
        RenaveStatusEnum.CheckOutVehicleZeroKm,
        RenaveStatusEnum.CheckedOutZeroKm,
      ].includes(props.cardData.step),
    [props.cardData.step]
  );
  const hideAccessButton = useMemo(
    () =>
      [
        RenaveStatusEnum.PendingZeroKm,
        RenaveStatusEnum.RejectedZeroKm,
        RenaveStatusEnum.ReturnedZeroKm,
      ].includes(props.cardData.step),
    [props.cardData.step]
  );
  const hideTimeInStock = useMemo(
    () =>
      [
        RenaveStatusEnum.PendingZeroKm,
        RenaveStatusEnum.RejectedZeroKm,
      ].includes(props.cardData.step),
    [props.cardData.step]
  );

  return (
    <RenaveCardWrapper>
      <RenaveCardDiv color={fillColor}>
        <CardHeaderAtom
          companyLogoImage={image}
          fillColor={fillColor}
          statusIconName={isPendingZeroKm ? "warning-color" : "approved-color"}
          statusText={RenaveStatusHelper.getStatusName(props.cardData.step, t)}
          headerTitle={
            isZeroKm
              ? `${props.cardData.vin}`
              : `${props.cardData.controlStockId || "0000"} - ${
                  props.cardData.plate ?? ""
                }`
          }
        />
        <hr />
        <CardBodyAtom
          bodyTitle={(isZeroKm ? t("Maker") : props.cardData.renavam) ?? ""}
          bodyEntryDate={formatDate(props.cardData.stockEntryDate)}
          bodyOutDate={formatDate(props.cardData.stockExitDate)}
          bodyTimeInStock={calculateTimeDiffInDays(
            props.cardData.stockEntryDate,
            props.cardData.stockExitDate
          )}
          bodyButtons={props.getBodyButtons(
            props.cardData.id,
            props.cardData.step,
            props.cardData.vin
          )}
          onClickAccess={props.getOnClickAccess(
            props.cardData.id,
            props.cardData.step,
            isZeroKm
          )}
          texts={{
            bodyTitle: isZeroKm ? t("Origin") : t("Renavam"),
            checkInDateText: t("CheckInDate"),
            checkOutDateText: t("CheckOutDate"),
            daysInStock: t("DaysInStock"),
            buttonAcessTitle: t("Access"),
          }}
          hideAccessButton={hideAccessButton}
          hideTimeInStock={hideTimeInStock}
        />
      </RenaveCardDiv>
    </RenaveCardWrapper>
  );
};
