import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "../../../constants";
import { HeaderTitleMolecule } from "../../molecules";
import { CreditStatementOrganism } from "../../organisms";

export const CreditStatementPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <HeaderTitleMolecule
        title={t("CreditStatement")}
        returnButtonActionOverride={() => history.push(paths.myStock())}
        navigationButtonText={t("BuyCredits")}
        navigationButtonAction={() =>
          history.push(paths.CreditAcquisitionPacksPage())
        }
      />
      <CreditStatementOrganism />
    </>
  );
};
