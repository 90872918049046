import styled from "styled-components";

export const GradientHR = styled.div`
  position: relative;
  height: 10px;
  width: 200%;
  left: -50%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.08), #f9f9f9);
`;

export const GenericLoadingWithText = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  height: 100%;

  color: ${(props) => props.theme.colors.BlueNavy};
`;

export const StepContentWrapper = styled.div`
  label {
    font-family: ${(props) => props.theme.fontFamily.OpenSans};
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;

    color: ${(props) => props.theme.colors.Dark};
  }
`;
