import styled from "styled-components";

export const CreditStatementHeaderList = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  color: ${(props) => props.theme.colors.Dark};
  font-size: 12px;
  line-height: 20px;
`;
