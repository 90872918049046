import { TypesHelper } from "c4u-web-components";

export interface IPostPacksRequest {
  expiration: string;
  cardholder: string;
  cardNumber: string;
  cvv: string;
  packId: number;
}

export interface IPostPackResponse {
  result: boolean;
}

export class PostPacksRequest {
  expiration: string | null;
  cardholder: string | null;
  cardNumber: string | null;
  cvv: string | null;
  packId: number | null;

  constructor({
    packId,
    cvv,
    cardholder,
    expiration,
    cardNumber,
  }: IPostPacksRequest) {
    this.packId = TypesHelper.toNumberNull(packId);
    this.cvv = TypesHelper.toStringNull(cvv);
    this.cardholder = TypesHelper.toStringNull(cardholder);
    this.expiration = TypesHelper.toStringNull(expiration);
    this.cardNumber = TypesHelper.toStringOnlyNumbersNull(cardNumber);
  }
}

export interface ICreditAcquisitionForm
  extends Omit<IPostPacksRequest, "packId"> {}
