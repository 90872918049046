import React from "react";
import ptBr from "date-fns/locale/pt-BR";
import { Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { DatePickerFormGroupWrapper } from "./formik-control-calendar.atom.style";
import { FormControlMask, MaskHelper } from "c4u-web-components";
import { useTranslation } from "react-i18next";

interface IProps {
  formik: any;
  property: string;
  label?: string;
  disabled?: boolean;
  xs?: string | number;
  sm?: string | number;
  md?: string | number;
  lg?: string | number;
  xl?: string | number;
}

export const FormikControlCalendarAtom: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { formik, property, label, disabled, ...sizes } = props;

  return (
    <DatePickerFormGroupWrapper as={Col} xs={4} {...sizes}>
      <Form.Label>{label}</Form.Label>

      <DatePicker
        disabled={disabled}
        selected={formik.values[property]}
        onChange={(date: Date) => formik.setFieldValue(property, date)}
        className={"form-control"}
        locale={ptBr}
        dateFormat={"dd/MM/yyyy"}
        placeholderText={t("Select the date")}
        customInput={
          <FormControlMask
            id={property}
            property={property}
            mask={MaskHelper.Date}
            isInvalid={!!formik.errors[property]}
          />
        }
      />

      <Form.Control.Feedback
        type="invalid"
        className={!!formik.errors[property] ? "d-flex" : "d-none"}
      >
        {formik.errors[property]}
      </Form.Control.Feedback>
    </DatePickerFormGroupWrapper>
  );
};
