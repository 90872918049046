import { ButtonSecondary, ButtonTertiary } from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../constants";
import { useRenave, useRenaveContext, useSessionContext } from "../../../hooks";
import {
  GetStatusRequest,
  IFormikSetErrors,
  IVehicleDanfeForm,
  PostEntryCancellationRequest,
  RenaveMenuStepsEnum,
  RenaveStatusEnum,
  TypeDocumentCodeEnum,
  VehicleDanfeRequest,
} from "../../../models";
import {
  ContentCardMolecule,
  DownloadDocumentMolecule,
  NextStepsDanfeMolecule,
  VehicleDanfeFormMolecule,
} from "../../molecules";
import {
  ManageVehicleContentWrapper,
  ButtonWrapper,
} from "../shared-style/organisms.shared-style";

interface IProps {
  viewMode?: boolean;
}

export const CheckInDanfeOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const {
    vehicleEntryContext,
    vehicleDanfeContext,
    setVehicleDanfeContext,
    setMenuCurrentStepContext,
    setVehicleEntryContext,
    menuCurrentStepContext,
  } = useRenaveContext();
  const { postVehicleDanfe, postEntryCancellation, getStatus } = useRenave();

  const {
    showGenericErrorModal,
    showGenericSuccessModal,
    handleFormikException,
  } = useSessionContext();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingCancelEntry, setIsSubmittingCancelEntry] = useState(false);
  const [goSubmit, setGoSubmit] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!!vehicleEntryContext?.firstAcess)
      showGenericSuccessModal(t("RenaveTermMessage"), null, t("Success"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleEntryContext?.firstAcess]);

  useEffect(() => {
    const newVehicleEntryContext = { ...vehicleEntryContext };
    newVehicleEntryContext.firstAcess = false;
    setVehicleEntryContext({ ...newVehicleEntryContext });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setVehicleEntryContext]);

  const handleSubmitVehicleDanfeForm = useCallback(
    async (
      values: IVehicleDanfeForm,
      { setErrors }: IFormikSetErrors<IVehicleDanfeForm>
    ) => {
      setIsSubmitting(true);
      try {
        const data = await postVehicleDanfe(
          new VehicleDanfeRequest({
            ...values,
            id: Number(params.id),
          })
        );

        showGenericSuccessModal(
          "",
          null,
          t("InvoiceSendSuccessfully"),
          t("Danfe")
        );

        setVehicleDanfeContext({
          id: Number(params.id),
          name: data.name,
          invoiceDate: data.invoiceDate,
          invoiceKey: values.invoiceKey,
        });

        setIsEditing(true);
      } catch (e: any) {
        handleFormikException(e, setErrors);
      } finally {
        setIsSubmitting(false);
        setGoSubmit(false);
      }
    },
    [
      handleFormikException,
      params.id,
      postVehicleDanfe,
      setVehicleDanfeContext,
      showGenericSuccessModal,
      t,
    ]
  );

  const handleEntryCancellation = useCallback(
    async (id: number) => {
      try {
        setIsSubmittingCancelEntry(true);
        await postEntryCancellation(
          new PostEntryCancellationRequest({ id })
        );

        showGenericSuccessModal(
          "",
          () => history.push(paths.myStock()),
          t("CancelEntrySuccessfully"),
          t("Danfe")
        );
      } catch (e) {
        showGenericErrorModal(t("CancelEntryError"), null, "", t("Danfe"));
      } finally {
        setIsSubmittingCancelEntry(false);
      }
    },
    [
      postEntryCancellation,
      showGenericSuccessModal,
      t,
      history,
      showGenericErrorModal,
    ]
  );

  const getStatusAsync = useCallback(async () => {
    if (
      !props.viewMode &&
      vehicleDanfeContext?.invoiceDate &&
      menuCurrentStepContext === RenaveMenuStepsEnum.CheckInDanfe
    ) {
      getStatus(
        new GetStatusRequest({
          id: Number(params.id),
        })
      )
        .then(async (data) => {
          if (data.step === RenaveStatusEnum.CheckedIn) {
            setMenuCurrentStepContext(RenaveMenuStepsEnum.CheckedIn);
            history.push(paths.vehicleCheckedIn(params.id));
          }
        })
        .catch((e) => {
          console.log("error get status", e);
        });
    }
  }, [
    getStatus,
    history,
    menuCurrentStepContext,
    params.id,
    props.viewMode,
    setMenuCurrentStepContext,
    vehicleDanfeContext?.invoiceDate,
  ]);

  useEffect(() => {
    getStatusAsync();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      vehicleDanfeContext?.invoiceDate &&
      menuCurrentStepContext === RenaveMenuStepsEnum.CheckInDanfe
    )
      setIsEditing(true);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ManageVehicleContentWrapper>
        <Row className="d-flex align-self-end">
          <Col>
            <DownloadDocumentMolecule
              id={Number(params.id)}
              title={t("RenaveLinkDownload")}
              typeDocument={TypeDocumentCodeEnum.EntryTerm}
              className="adjust-bottom-gap"
              inline
            />
          </Col>
        </Row>
        <ContentCardMolecule title={t("VehicleDanfe")}>
          <VehicleDanfeFormMolecule
            setGoSubmit={setGoSubmit}
            goSubmit={goSubmit}
            onSubmit={handleSubmitVehicleDanfeForm}
            setFormValid={setFormValid}
            initialValues={vehicleDanfeContext}
            vehicleDanfe={vehicleDanfeContext}
            editing={isEditing}
            viewMode={props.viewMode}
          />
        </ContentCardMolecule>

        {vehicleDanfeContext && (isEditing || !!props.viewMode) && (
          <ContentCardMolecule title={t("NextSteps")}>
            <NextStepsDanfeMolecule plate={vehicleEntryContext?.plate} />
          </ContentCardMolecule>
        )}
      </ManageVehicleContentWrapper>

      <ButtonWrapper>
        <ButtonSecondary
          sizex="md"
          loading={isSubmittingCancelEntry}
          onClick={() => {
            handleEntryCancellation(Number(params.id));
          }}
          disabled={!!props.viewMode || isSubmitting || isSubmittingCancelEntry}
        >
          {t("CancelEntry")}
        </ButtonSecondary>

        {!isEditing && !props.viewMode ? (
          <ButtonTertiary
            sizex="md"
            loading={isSubmitting}
            onClick={() => {
              setGoSubmit(true);
            }}
            disabled={!formValid}
          >
            {t("sendInvoiceKey")}
          </ButtonTertiary>
        ) : (
          <ButtonSecondary
            sizex="md"
            onClick={() => {
              setIsEditing(false);
            }}
            disabled={
              !vehicleDanfeContext ||
              !!props.viewMode ||
              isSubmittingCancelEntry
            }
          >
            {t("Edit")}
          </ButtonSecondary>
        )}
      </ButtonWrapper>
    </>
  );
};
