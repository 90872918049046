import { Combo, Translator } from "c4u-web-components";
import { StdEnum } from "../models";

export class EnumHelper {
  static getComboFromEnum(enumObj: StdEnum, t: Translator) {
    return Object.entries(enumObj)
      .filter(([k, _]) => !isNaN(parseInt(k)))
      .map(([k, v]) => new Combo({ title: t(v.toString()), value: k }));
  }
}
