import styled from "styled-components";

export const SelectCheckoutWrapper = styled.div``;

export const SelectCheckoutButtonText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  color: ${({ theme }) => theme.colors.Dark};
`;

export const SelectCheckoutButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

  margin: 22px 0;

  button {
    margin: unset;
  }
`;
