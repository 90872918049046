import styled from "styled-components";

export const RestrictionList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  gap: 5px;
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const ListItemDescription = styled.span`
  font-size: 14px;
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: 700;
  line-height: 20px;
`;

export const ListItemDescriptionLink = styled.span`
  font-weight: 400;
  text-decoration: underline;

  color: ${(props) => props.theme.colors.BlueNavy};

  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  opacity: ${(props) => (props.onClick ? 1 : 0.4)};
`;
