import styled from "styled-components";

interface IPropsStatusColor {
  color: string;
}

const RenaveCardWrapper = styled.div``;

const RenaveCardDiv = styled.div<IPropsStatusColor>`
  background: white;
  padding: 21px 30px 21px 45px;
  box-shadow: 0px 8px 10px 0px #00000014;
  border-radius: 10px;
  border-left: 11px solid ${(props) => props.color};
`;

export { RenaveCardDiv, RenaveCardWrapper };
