export enum RenaveMenuStepsEnum {
  Aptness = 1,
  CheckInVehicle,
  CheckInDanfe,
  CheckedIn,
  CheckOutVehicle,
  CheckOutDanfe,
  CheckedOut,
}

export enum RenaveMenuStepsZeroKmEnum {
  CheckInVehicleZeroKm = 1,
  CheckedInZeroKm,
  CheckOutVehicleZeroKm,
  CheckedOutZeroKm,
}
