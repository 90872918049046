import React, { useEffect } from "react";
import { ManageStockBasePage } from "../..";
import { useRenaveContext } from "../../../../hooks";
import { RenaveMenuStepsEnum } from "../../../../models";
import { VehicleAptnessOrganism } from "../../../organisms";

export const CheckInAptnessPage: React.FC = () => {
  const {
    setMenuSelectedStepContext,
    menuCurrentStepContext,
    menuSelectedStepContext,
  } = useRenaveContext();

  useEffect(() => {
    setMenuSelectedStepContext(RenaveMenuStepsEnum.Aptness);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ManageStockBasePage>
      <VehicleAptnessOrganism
        disabled
        hideModal
        updateRestrictions={menuSelectedStepContext === menuCurrentStepContext}
      />
    </ManageStockBasePage>
  );
};
