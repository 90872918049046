import styled from "styled-components";

export const MyStockPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 50px;

  margin: 36px 0 0;
`;

export const MyStockListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 24px;
`;

export const NoVehiclesFoundDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  font-family: ${({ theme }) => theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.MediumGray};
`;
