import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export const useQuery = () => {
  const location = useLocation();

  const query = useMemo(() => {
    const queryString =
      location.search.trim().length > 0
        ? location.search
        : location.pathname.replace("/", "?");
    return new URLSearchParams(queryString);
  }, [location.search, location.pathname]);

  const get = (name: string) => {
    return query.get(name);
  };

  return { get };
};
