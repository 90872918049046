import { SpinnerCenteredAtom } from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRenave, useSessionContext } from "../../../../hooks";
import {
  CreditPackTypeEnum,
  ICreditPacks,
  IGetPacksResponse,
} from "../../../../models";
import { CreditPacksMolecule } from "../../../molecules";
import {
  CardSectionHR,
  WrapperCardDiv,
} from "./credit-acquisition-packs.organism.style";

interface IPacksSectionData {
  title: string;
  cards: ICreditPacks[];
}

export const CreditAcquisitionPacksOrganism: React.FC = () => {
  const { t } = useTranslation();

  const [packs, setPacks] = useState<IPacksSectionData[]>();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { getPacks } = useRenave();

  const { showGenericErrorModal } = useSessionContext();

  const pushPacks = useCallback(
    (
      data: IGetPacksResponse,
      packsLocal: IPacksSectionData[],
      packsType: CreditPackTypeEnum,
      sectionTitle: string
    ) => {
      const filteredPacks = data.packs.filter((f) => f.type === packsType);
      if (filteredPacks.length)
        packsLocal.push({
          title: sectionTitle,
          cards: filteredPacks,
        });
    },
    []
  );

  const handleGetPacks = useCallback(async () => {
    setIsSubmitting(true);
    getPacks()
      .then((data) => {
        const packsLocal: IPacksSectionData[] = [];

        pushPacks(
          data,
          packsLocal,
          CreditPackTypeEnum.zeroKm,
          t("ZeroKmPacksSectionTitle")
        );
        pushPacks(
          data,
          packsLocal,
          CreditPackTypeEnum.usedVehicles,
          t("UsedVehiclesPacksSectionTitle")
        );
        pushPacks(
          data,
          packsLocal,
          CreditPackTypeEnum.transferDetran,
          t("TransferDetranPacksSectionTitle")
        );

        setPacks(packsLocal);
      })
      .catch(() => {
        showGenericErrorModal(
          t("GenericErrorMsg"),
          null,
          t("Error loading packs"),
          t("Error")
        );
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }, [getPacks, pushPacks, showGenericErrorModal, t]);

  useEffect(() => {
    handleGetPacks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WrapperCardDiv>
      {isSubmitting ? (
        <SpinnerCenteredAtom classNames="mx-auto" />
      ) : (
        packs?.length &&
        packs.map(
          (m, i) =>
            !!m.cards.length && (
              <>
                <CreditPacksMolecule title={m.title} packs={m.cards} />
                {i < packs.length - 1 && <CardSectionHR />}
              </>
            )
        )
      )}
    </WrapperCardDiv>
  );
};
