import { Form } from "react-bootstrap";
import styled from "styled-components";

export const WrapperSectionRegister = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 743px;

  gap: 19px;
`;

export const TitleSectionRegisterDiv = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;

  letter-spacing: -0.15px;

  color: ${(props) => props.theme.colors.BlueNavy};
`;

export const LabelField = styled(Form.Label)`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  color: #4a4a4a;
`;
