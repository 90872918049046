import { TypesHelper } from "c4u-web-components";

export interface IGetPlateRegisterRequest {
  plate: string;
}

export class GetPlateRegisterRequest {
  plate: string | null;

  constructor({ plate }: IGetPlateRegisterRequest) {
    this.plate = TypesHelper.toStringNull(plate);
  }
}
