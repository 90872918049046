import { TypesHelper } from "c4u-web-components";

export interface IPostDealershipResponse {
  id: number;
}

export interface IPostDealershipRequest {
  phone: string;
  updateMyPhone?: boolean;
}

export class PostDealershipRequest {
  phone: string | null;
  updateMyPhone: boolean | null;

  constructor({ phone, updateMyPhone }: IPostDealershipRequest) {
    this.phone = TypesHelper.toStringNull(phone);
    this.updateMyPhone = TypesHelper.toBooleanNull(updateMyPhone);
  }
}

export interface IDealershipRegisterForm extends IPostDealershipRequest {
  nameUser?: string;
  email?: string;
  cnpj?: string;
  zipCode?: string;
  state?: string;
  neighborhood?: string;
  number?: string;
  city?: string;
  complement?: string;
  street?: string;
  legalName?: string;
  name?: string;
  confirmData?: boolean;
  dealershipPhone?: string;
}
