import { MsgValidation, YupHelper } from "c4u-web-components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ISaveOutOfStockPersonRequest } from "../../../models";

export const CheckOutPersonFormMoleculeValidation = () => {
  const { t } = useTranslation();

  const msgRequired = t(MsgValidation.RequiredField);
  const msgInvalid = t(MsgValidation.Invalid);
  const msgEmail = t(MsgValidation.Email);

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCpfCnpjValid",
    YupHelper.isCpfCnpjValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isDatePtBrValid",
    YupHelper.isDatePtBrValid
  );

  const validations = Yup.object<ISaveOutOfStockPersonRequest>({
    id: Yup.string().required(msgRequired),
    buyerDocument: Yup.string()
      .required(msgRequired)
      .isCpfCnpjValid(msgInvalid),
    buyerEmail: Yup.string().required(msgRequired).email(msgEmail),
    buyerName: Yup.string().required(msgRequired).max(100, msgInvalid),
    city: Yup.string().required(msgRequired).max(100, msgInvalid),
    docType: Yup.string().required(msgRequired),
    number: Yup.string().required(msgRequired).max(6, msgInvalid),
    sellValue: Yup.string().required(msgRequired),
    state: Yup.string().required(msgRequired).max(2, msgInvalid),
    street: Yup.string().required(msgRequired).max(200, msgInvalid),
    zipCode: Yup.string().required(msgRequired),
    neighborhood: Yup.string().required(msgRequired).max(100, msgInvalid),
    exitDate: Yup.string().isDatePtBrValid(msgInvalid).required(msgRequired),
  });

  return validations;
};
