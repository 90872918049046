import React, { useEffect } from "react";
import { useRenaveContext } from "../../../../hooks";
import { RenaveMenuStepsEnum } from "../../../../models";
import { CheckOutVehicleOrganism } from "../../../organisms";
import { ManageStockBasePage } from "../../manage-stock-base/manage-stock-base.page";

export const CheckOutVehiclePage: React.FC = () => {
  const {
    menuCurrentStepContext,
    menuSelectedStepContext,
    setMenuSelectedStepContext,
  } = useRenaveContext();

  useEffect(() => {
    setMenuSelectedStepContext(RenaveMenuStepsEnum.CheckOutVehicle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ManageStockBasePage>
      <CheckOutVehicleOrganism
        viewMode={menuSelectedStepContext < menuCurrentStepContext}
      />
    </ManageStockBasePage>
  );
};
