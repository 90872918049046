import { TypesHelper } from "c4u-web-components";
import { RejectZeroKmReasonsEnum } from "../..";

export interface IPostRejectZeroKmRequest {
  id: number;
  rejectionReason: RejectZeroKmReasonsEnum;
}

export class PostRejectZeroKmRequest {
  id: number | null;
  rejectionReason: RejectZeroKmReasonsEnum | null;
  constructor({ id, rejectionReason }: IPostRejectZeroKmRequest) {
    this.id = TypesHelper.toNumberNull(id);
    this.rejectionReason = TypesHelper.toNumberNull(rejectionReason);
  }
}

export interface IPostCancelRejectionZeroKmRequest {
  id: number;
}

export class PostCancelRejectionZeroKmRequest {
  id: number | null;
  constructor({ id }: IPostCancelRejectionZeroKmRequest) {
    this.id = TypesHelper.toNumberNull(id);
  }
}
