import { SpinnerCenteredAtom } from "c4u-web-components";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FileHelper } from "../../../helper";
import { useRenave, useSessionContext } from "../../../hooks";
import { GetDocumentRequest, TypeDocumentCodeEnum } from "../../../models";
import { RenaveIconDonwload } from "../../assets";
import {
  DownloadButtonWrapper,
  DownloadTitleDiv,
} from "./download-document.molecule.style";

interface IProps {
  id: number;
  title: string;
  typeDocument: TypeDocumentCodeEnum;
  disabled?: boolean;
  inline?: boolean;
  className?: string;
}

export const DownloadDocumentMolecule: React.FC<IProps> = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { showGenericErrorModal } = useSessionContext();

  const { getDocument } = useRenave();

  const { id, typeDocument } = props;

  const handleDownloadDocument = useCallback(async () => {
    setIsLoading(true);
    FileHelper.downloadBase64PdfFile(
      () =>
        getDocument(
          new GetDocumentRequest({
            id,
            typeDocumentCode: typeDocument,
          })
        ),
      window,
      () => showGenericErrorModal(t("GenericErrorMsg"), null, t("Error")),
      setIsLoading
    );
  }, [getDocument, id, showGenericErrorModal, t, typeDocument]);

  return (
    <DownloadButtonWrapper
      onClick={!props.disabled ? handleDownloadDocument : undefined}
      className={props.className + (props.inline ? " show-inline" : "")}
    >
      <DownloadTitleDiv>{props.title}</DownloadTitleDiv>
      {isLoading ? (
        <SpinnerCenteredAtom size="sm" />
      ) : (
        <img src={RenaveIconDonwload} alt="Download Button Icon" />
      )}
    </DownloadButtonWrapper>
  );
};
