import { ButtonSecondary, ButtonTertiary } from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "../../../constants";
import { useRenave, useRenaveContext, useSessionContext } from "../../../hooks";
import { CheckoutTypeEnum } from "../../../models";
import {
  ContentCardMolecule,
  CheckOutDealershipFormMolecule,
  CheckOutPersonFormMolecule,
} from "../../molecules";
import { ManageVehicleContentWrapper } from "../shared-style/organisms.shared-style";

interface IProps {
  viewMode?: boolean;
}

export const CheckOutVehicleOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { vehicleCheckoutContext } = useRenaveContext();
  const history = useHistory();
  const { showGenericWarningModal } = useSessionContext();

  const [typeButtonChoice, setTypeButtonChoice] =
    useState<CheckoutTypeEnum | null>(null);
  const [goSubmit, setGoSubmit] = useState<boolean>(false);
  const [isSubmiting, setIsSubmitting] = useState<boolean>(false);

  const { getDailyBalance } = useRenave();

  const handleIsSubmiting = (submiting: boolean) => {
    setIsSubmitting(submiting);
  };

  const validateCreditsModal = useCallback(() => {
    showGenericWarningModal(t("NoCredtisValidationMsg"), () => {
      history.replace(paths.CreditAcquisitionPacksPage());
    });
  }, [showGenericWarningModal, history, t]);

  const hasCredits = useCallback(async () => {
    const response = await getDailyBalance();
    return response?.dailyBalance > 0;
  }, [getDailyBalance]);

  const validateCredits = useCallback(async () => {
    try {
      const credits = await hasCredits();
      if (!credits) {
        validateCreditsModal();
        return false;
      }
      return true;
    } catch (error) {
      validateCreditsModal();
      return false;
    }
  }, [validateCreditsModal, hasCredits]);

  useEffect(() => {
    validateCredits();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (goSubmit) setGoSubmit(false);
  }, [goSubmit]);

  useEffect(() => {
    if (vehicleCheckoutContext)
      setTypeButtonChoice(vehicleCheckoutContext.checkOutType);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Row>
        <Col>
          <b>{t("OuOfStockSelectOptions")}</b>
        </Col>
      </Row>

      <div className={"m-4 d-flex align-items-center justify-content-center"}>
        <Row>
          <Col md="auto">
            {typeButtonChoice === CheckoutTypeEnum.TransferAuthorization ? (
              <ButtonTertiary sizex={"lg"} disabled={props.viewMode}>
                {t("TransferToDealership")}
              </ButtonTertiary>
            ) : (
              <ButtonSecondary
                sizex={"lg"}
                onClick={() =>
                  setTypeButtonChoice(CheckoutTypeEnum.TransferAuthorization)
                }
                disabled={props.viewMode}
              >
                {t("TransferToDealership")}
              </ButtonSecondary>
            )}
          </Col>
          <Col md="auto">
            {typeButtonChoice === CheckoutTypeEnum.Exit ? (
              <ButtonTertiary sizex={"lg"} disabled={props.viewMode}>
                {t("TransferToCustomer")}
              </ButtonTertiary>
            ) : (
              <ButtonSecondary
                sizex={"lg"}
                onClick={() => setTypeButtonChoice(CheckoutTypeEnum.Exit)}
                disabled={props.viewMode}
              >
                {t("TransferToCustomer")}
              </ButtonSecondary>
            )}
          </Col>
        </Row>
      </div>
      {typeButtonChoice === CheckoutTypeEnum.TransferAuthorization && (
        <ManageVehicleContentWrapper>
          <ContentCardMolecule title={t("TransferToDealership")}>
            <CheckOutDealershipFormMolecule
              goSubmit={goSubmit}
              setIsSubmitting={handleIsSubmiting}
              creditsToSubmit={validateCredits}
              viewMode={props.viewMode}
            />
          </ContentCardMolecule>
        </ManageVehicleContentWrapper>
      )}
      {typeButtonChoice === CheckoutTypeEnum.Exit && (
        <ManageVehicleContentWrapper>
          <ContentCardMolecule title={t("TransferToCustomer")}>
            <CheckOutPersonFormMolecule
              goSubmit={goSubmit}
              setIsSubmitting={handleIsSubmiting}
              creditsToSubmit={validateCredits}
              viewMode={props.viewMode}
            />
          </ContentCardMolecule>
        </ManageVehicleContentWrapper>
      )}
      {typeButtonChoice !== null && (
        <Row className="m-4">
          <Col md={4}></Col>
          <Col md={8} className={"text-right m-0 p-0"}>
            <div className="w-100">
              <Row className="align-items-right justify-content-end">
                <Col className="m-0 p-0 ml-2 text-right" md="auto">
                  <ButtonTertiary
                    sizex={"md"}
                    onClick={() => setGoSubmit(true)}
                    loading={isSubmiting}
                    disabled={props.viewMode}
                  >
                    {t("Advance")}
                  </ButtonTertiary>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};
