import { Form } from "react-bootstrap";
import styled from "styled-components";

export const SelectVehicleButtonStyled = styled.button`
  border: none;
  background: inherit;
  margin-bottom: 10px;
  margin: 0px;
  padding: 0px;

  font-family: Helvetica;
  font-size: 16px;
  line-height: 28px;
  text-decoration-line: underline;

  color: ${(props) => props.theme.colors.BlueHigh};
`;

export const WrapperModalButtonOk = styled.div`
  display: flex;
  align-items: center;
  padding-top: 20px;
  text-transform: uppercase;
`;

export const WrapperButtonsConfirm = styled.div`
  display: flex;
  margin: 5px 0;
  button:first-child {
    margin-right: 20px;
  }
`;

export const MainWrapperVehicleDataForm = styled.div`
  min-height: 500px;

  @media (max-width: 600px) {
    min-height: 600px;
  }
`;

export const StyledFormRow = styled(Form.Row)`
  input {
    height: unset;
  }

  .text-vehicle-brand {
    margin: 0 0 6px;
  }
  .text-vehicle-year {
    margin: 6px 0;
  }
  .text-vehicle-model {
    margin: 6px 0;
  }
  .text-vehicle-version {
    margin: 6px 0;
  }
`;
