import { NumberHelper } from "c4u-web-components";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  IGetCreditStatementResponse,
  TypeTransactionEnum,
} from "../../../models";
import {
  CreditStatementMoneyText,
  CreditStatementNegativeMoneyText,
  CreditStatementText,
  CreditStatementWrapper,
} from "./credit-statement-data-list.molecule.style";

interface IProps {
  creditStatementData?: IGetCreditStatementResponse;
}

export const CreditStatementDataListMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { creditStatementData } = props;

  return (
    <Col sm={{ span: 10, offset: 1 }}>
      <CreditStatementWrapper>
        <Row className="mt-3 text-center">
          {creditStatementData === undefined ? (
            <Col sm={12}>
              <CreditStatementText>
                {t("NoTransactionsFound")}
              </CreditStatementText>
            </Col>
          ) : (
            <>
              <Col sm={2}>
                <CreditStatementText>
                  {creditStatementData.date}
                </CreditStatementText>
              </Col>

              <Col sm={4}>
                <CreditStatementText>
                  {creditStatementData.description}
                </CreditStatementText>
              </Col>

              <Col sm={3}>
                <CreditStatementText>
                  {creditStatementData.plate}
                </CreditStatementText>
              </Col>

              <Col sm={3}>
                {creditStatementData.typeTransaction ===
                TypeTransactionEnum.Entry ? (
                  <CreditStatementMoneyText>
                    {" R$ "}
                    {NumberHelper.toFormatString(creditStatementData.value, 2)}
                  </CreditStatementMoneyText>
                ) : creditStatementData.typeTransaction ===
                  TypeTransactionEnum.Exit ? (
                  <CreditStatementNegativeMoneyText>
                    {"- R$ "}
                    {NumberHelper.toFormatString(creditStatementData.value, 2)}
                  </CreditStatementNegativeMoneyText>
                ) : (
                  <CreditStatementMoneyText></CreditStatementMoneyText>
                )}
              </Col>
            </>
          )}
        </Row>
      </CreditStatementWrapper>
    </Col>
  );
};
