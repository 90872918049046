import { ICardRenave } from "../models";

export const cardsRenaveInfo: ICardRenave[] = [
  {
    title: "CostReduction",
    description: [
      "TaxReductionInStates",
      "EliminationOfNotaryCosts",
      "WithdrawalInDemandAtInspection",
    ],
  },
  {
    title: "SimplifyProcesses",
    description: [
      "OnlineVerification",
      "SimplifyEntryInspections",
      "TimeReduction",
      "OnlineProcess",
      "EliminationOfVisits",
    ],
  },
  {
    title: "IrrigateTheMarket",
    description: ["MerchantTransformation", "PossibilitiesinStock"],
  },
];
