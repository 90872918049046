import { useAuth0 } from "@auth0/auth0-react";
import { AxiosInstance, AxiosResponse } from "axios";
import { useCallback } from "react";
import {
  ConflictRequestError,
  GeneralRequestError,
  RequestError,
} from "../../../../models";

export const useRequest = (
  http: AxiosInstance,
  path: string,
  headersParam: any = null
) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const buildUrl = useCallback(
    (url: string) => {
      return url ? `${path}/${url}` : path;
    },
    [path]
  );

  const buildHeaders = useCallback(
    async (customHeaders = {}) => {
      const token = isAuthenticated
        ? await getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            scope: process.env.REACT_APP_AUTH0_SCOPE,
          })
        : localStorage.getItem("access_token");

      const headers = headersParam
        ? headersParam
        : {
            Authorization: `Bearer ${token}`,
            "Accept-Language": "pt-BR,pt;q=0.9",
          };

      return { ...headers, ...customHeaders };
    },
    [headersParam, isAuthenticated, getAccessTokenSilently]
  );

  const getError = useCallback(
    (e: any): RequestError | ConflictRequestError | GeneralRequestError => {
      if (e?.response && e.response?.status === 412)
        return new RequestError(e.response.data);

      if (e?.response && e.response?.status === 409)
        return new ConflictRequestError(e.response.data);

      console.log("General error", e);
      return new GeneralRequestError(e);
    },
    []
  );

  const callApi = useCallback(
    async (
      url: string,
      method: string,
      config: any,
      data: any = null,
      retry: number = 1
    ) => {
      let i = 1;
      while (true) {
        const requestConfig = config;
        requestConfig.url = buildUrl(url);
        requestConfig.headers = await buildHeaders(config.headers);
        requestConfig.data = data;
        requestConfig.method = method;
        try {
          return await http.request(requestConfig);
        } catch (e: any) {
          if (
            (e as any)?.message === "Network Error" &&
            !(e as any)?.tryEntries?.length &&
            i++ < retry
          )
            continue;

          throw getError(e);
        }
      }
    },
    [buildHeaders, buildUrl, getError, http]
  );

  return {
    get: useCallback(
      async <T = any>(
        url: string,
        config = {},
        retry?: number
      ): Promise<AxiosResponse<T>> => callApi(url, "GET", config, null, retry),
      [callApi]
    ),
    delete: useCallback(
      async <T = any>(
        url: string,
        config = {},
        retry?: number
      ): Promise<AxiosResponse<T>> =>
        callApi(url, "DELETE", config, null, retry),
      [callApi]
    ),
    put: useCallback(
      async <T = any>(
        url: string,
        data: any,
        config = {},
        retry?: number
      ): Promise<AxiosResponse<T>> => callApi(url, "PUT", config, data, retry),
      [callApi]
    ),
    post: useCallback(
      async <T = any>(
        url: string,
        data: any,
        config = {},
        retry?: number
      ): Promise<AxiosResponse<T>> => callApi(url, "POST", config, data, retry),
      [callApi]
    ),
  };
};
