import {
  ButtonSecondary,
  CoxIcon,
  SpinnerCenteredAtom,
} from "c4u-web-components";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FileHelper } from "../../../helper";
import { useRenave, useRenaveContext, useSessionContext } from "../../../hooks";
import {
  GetDocumentRequest,
  IDocumentModel,
  TypeDocumentCodeEnum,
  TypeMethodSendingAtpveEnum,
  TypeSignatureEnum,
} from "../../../models";
import { IconPdfUpload } from "../../assets/index";
import {
  ButtonWrapperFilePreviewPdf,
  DivPdf,
  DivUploadPdf,
  FileInputUploadPdf,
  FilePreviewPdf,
  ImgUploadPdf,
  ParagrafoUploadPdf,
  WrapperUploadPdf,
} from "./upload-document-pdf.atom.style";

interface IProps {
  title: string;
  index: number;
  isSubmitting?: boolean;
  setValidDocument: (v: boolean) => void;
  typeSignature?: TypeSignatureEnum;
  disabled?: boolean;
}

export const UploadDocumentPdfAtom: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { uploadDocument, getDocument } = useRenave();
  const { setDocumentContext, documentContext } =
    useRenaveContext();
  const { showGenericErrorModal } = useSessionContext();
  const params = useParams<{ id: string }>();

  const {
    title,
    index,
    setValidDocument,
    isSubmitting,
    disabled: disabledProps,
  } = props;

  const fileInput = useRef(document.createElement("input"));

  const [selectedFile, setSelectedFiles] = useState<any>();
  const [currentFile, setCurrentFile] = useState<string>();
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const validDocument = (img: any) => {
    const allowedSize = 1000000;

    return img && allowedSize >= img.size;
  };

  const selectFile = (event: any) => {
    const files = event.target.files;
    if (validDocument(files[0])) {
      setSelectedFiles(files[0]);
    } else {
      //invalid document
    }
  };

  const handleDownloadDocument = useCallback(async () => {
    setIsLoading(true);
    FileHelper.downloadBase64PdfFile(
      () =>
        getDocument(
          new GetDocumentRequest({
            id: Number(params.id),
            typeDocumentCode: TypeDocumentCodeEnum.ATPV,
          })
        ),
      window,
      () => showGenericErrorModal(t("GenericErrorMsg"), null, t("Error")),
      setIsLoading
    );
  }, [getDocument, showGenericErrorModal, t, params.id]);

  useEffect(() => {
    const upload = () => {
      const file = selectedFile;

      if (!file) return;

      setFileName(file.name.replace(".", " "));

      setCurrentFile(URL.createObjectURL(file));

      const valuesDocumentContext = {
        typeMethodSending: TypeMethodSendingAtpveEnum.UniqueFile,
        file: [],
      } as IDocumentModel;

      valuesDocumentContext.file.push({
        file: file,
        title: title,
        index: index,
      });

      setCurrentFile(URL.createObjectURL(file));
      setDocumentContext(valuesDocumentContext);
      setValidDocument(false);
      setSelectedFiles(null);
    };

    if (selectedFile) upload();
  }, [selectedFile, uploadDocument]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (documentContext) {
      const item = documentContext.file.find((x) => x.index === index);

      if (item) {
        setCurrentFile(URL.createObjectURL(item.file));
        setFileName(item.title);
      }
    }
  }, [documentContext]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WrapperUploadPdf>
      {!currentFile ? (
        <>
          <DivPdf>
            <FileInputUploadPdf
              accept="application/pdf"
              onChange={(ev) => selectFile(ev)}
              ref={fileInput}
              type="file"
              disabled={isSubmitting}
            />
            <ImgUploadPdf src={IconPdfUpload} alt="" />

            <ParagrafoUploadPdf> {t("AddPdfDocument")} </ParagrafoUploadPdf>
          </DivPdf>
        </>
      ) : (
        <>
          <DivUploadPdf>
            <FilePreviewPdf>
              <FileInputUploadPdf
                accept="application/pdf"
                onChange={(ev) => selectFile(ev)}
                ref={fileInput}
                type="file"
                disabled={isSubmitting}
              />
            </FilePreviewPdf>

            <ButtonWrapperFilePreviewPdf>
              <CoxIcon name="checked" width={30} height={30} />
              <ParagrafoUploadPdf>{fileName}</ParagrafoUploadPdf>
              {!disabledProps ? (
                <ButtonSecondary
                  sizex={"md"}
                  sizey={"thin"}
                  disabled={isSubmitting}
                  onClick={() => fileInput.current.click()}
                >
                  {t("change document")}
                </ButtonSecondary>
              ) : (
                <>
                  {isLoading ? (
                    <SpinnerCenteredAtom size="sm" />
                  ) : (
                    <ButtonSecondary
                      sizex={"md"}
                      sizey={"thin"}
                      onClick={() => handleDownloadDocument()}
                    >
                      {t("View document")}
                    </ButtonSecondary>
                  )}
                </>
              )}
            </ButtonWrapperFilePreviewPdf>
          </DivUploadPdf>
        </>
      )}
    </WrapperUploadPdf>
  );
};
