/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

  html, body, #root, .container {
    height: 100%;
  }

  .container {
    height: calc(100% - 60px);
  }

  * {
    font-family: ${(props) => props.theme.fontFamily.OpenSans}
  }

  a {
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
  .show{
    display: initial;
  }
  .hide{
    display: none;
  }

  .form-default {
    .form-label {
          font-family: ${(props) => props.theme.fontFamily.OpenSans};
          font-size: 14px;
          font-weight: bold;
          color: ${(props) => props.theme.colors.Dark};
          line-height: 22px;
      }
      input, select {
        height: 40px;
        background-color: ${(props) => props.theme.colors.GhostWhite};
        border: 1px solid ${(props) => props.theme.colors.DarkGrayOpacity};
        border-radius: 4px;
        box-sizing: border-box;
        background-image: none !important;
      }
      textarea {
          background-color: ${(props) => props.theme.colors.GhostWhite};
          border: 1px solid ${(props) => props.theme.colors.DarkGray};
          border-radius: 4px;
      }
    }

  .default-align {
    @media (min-width: 990px){
      margin-left: 100px;;
    }
  }

  .cancel-default-align {
    @media (min-width: 990px){
      margin-left: -100px;;
    }
  }
`;
