import {
  ButtonTertiary,
  OptionsSelectionVehicleMolecule,
} from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../constants";
import { useRenave, useRenaveContext, useSessionContext } from "../../../hooks";
import {
  ICheckInZeroKmModel,
  PostCheckInZeroKmRequest,
  RenaveMenuStepsZeroKmEnum,
} from "../../../models";
import {
  ContentCardMolecule,
  ZeroKmVehicleDataFormMolecule,
} from "../../molecules";
import {
  ButtonWrapper,
  ManageVehicleContentWrapper,
} from "../shared-style/organisms.shared-style";

interface IProps {
  viewMode?: boolean;
}

export const CheckInZeroKmOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const history = useHistory();

  const { postCheckInZeroKm } = useRenave();
  const { handleFormikException } = useSessionContext();
  const {
    kbbVehicleContext,
    zeroKmVehicleEntryContext,
    setZeroKmVehicleEntryContext,
    setMenuCurrentStepZeroKmContext,
    vehicleCategoryContext,
    changeVehicleCategoryContext,
  } = useRenaveContext();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [goSubmit, setGoSubmit] = useState(false);

  const handleSubmitZeroKmEntryForm = useCallback(
    async (values: ICheckInZeroKmModel) => {
      try {
        if (zeroKmVehicleEntryContext) {
          const requestData = {
            ...zeroKmVehicleEntryContext,
            ...values,
          };
          await postCheckInZeroKm(new PostCheckInZeroKmRequest(requestData));
          setZeroKmVehicleEntryContext(requestData);
        }
        setMenuCurrentStepZeroKmContext(
          RenaveMenuStepsZeroKmEnum.CheckedInZeroKm
        );
        history.push(paths.vehicleCheckedInZeroKm(params.id));
      } catch (e) {
        handleFormikException(e);
      } finally {
        setGoSubmit(false);
      }
    },
    [
      handleFormikException,
      history,
      params.id,
      postCheckInZeroKm,
      setMenuCurrentStepZeroKmContext,
      setZeroKmVehicleEntryContext,
      zeroKmVehicleEntryContext,
    ]
  );

  useEffect(() => {
    if (goSubmit) setGoSubmit(false);
  }, [goSubmit]);

  useEffect(() => {
    if (kbbVehicleContext && zeroKmVehicleEntryContext) {
      setZeroKmVehicleEntryContext({
        ...zeroKmVehicleEntryContext,
        brand: kbbVehicleContext.brandName,
        modelYear: kbbVehicleContext.year,
        model: kbbVehicleContext.modelName,
        version: kbbVehicleContext.versionName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kbbVehicleContext]);

  useEffect(() => {
    if (params.id && zeroKmVehicleEntryContext) {
      setZeroKmVehicleEntryContext({
        ...zeroKmVehicleEntryContext,
        id: parseInt(params.id),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  return (
    <>
      <ManageVehicleContentWrapper>
        <ContentCardMolecule title={t("ZeroKmVehicle")}>
          <OptionsSelectionVehicleMolecule
            vehicleCategory={vehicleCategoryContext}
            setVehicleCategory={changeVehicleCategoryContext}
            translate={t}
          />
          &nbsp;&nbsp;
          <ZeroKmVehicleDataFormMolecule
            initialValues={zeroKmVehicleEntryContext}
            setIsSubmitting={setIsSubmitting}
            goSubmit={goSubmit}
            onSubmit={handleSubmitZeroKmEntryForm}
            disableAll={!!props.viewMode}
            vehicleCategory={vehicleCategoryContext}
            setVehicleCategory={changeVehicleCategoryContext}
          />
        </ContentCardMolecule>
      </ManageVehicleContentWrapper>

      <ButtonWrapper>
        <ButtonTertiary
          sizex="md"
          loading={isSubmitting}
          onClick={() => setGoSubmit(true)}
          disabled={!!props.viewMode}
        >
          {t("Save")}
        </ButtonTertiary>
      </ButtonWrapper>
    </>
  );
};
