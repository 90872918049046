import { ButtonTertiary } from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../constants";
import { useRenave, useRenaveContext } from "../../../hooks";
import {
  RenaveMenuStepsEnum,
  GetDocumentRequest,
  IDownloadDocumentModel,
  TypeDocumentCodeEnum,
} from "../../../models";
import { CardDownloadDocumentMolecule } from "../../molecules";
import { ButtonWrapper } from "../shared-style/organisms.shared-style";
import { VehicleCheckedInContentWrapper } from "./vehicle-checked-in.organism.style";

interface IProps {
  viewMode?: boolean;
}

export const VehicleCheckedInOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { getDocument } = useRenave();
  const history = useHistory();
  const { setMenuCurrentStepContext } = useRenaveContext();
  const params = useParams<{ id: string }>();

  const downloadDocuments: IDownloadDocumentModel[] = [
    {
      title: t("CrlvIssued"),
      icon: "checked",
      text: t("DocumentGeneratedIn"),
      typeDocumentCode: TypeDocumentCodeEnum.CRLV,
    },
    {
      title: t("SecurityCodeCrv"),
      icon: "checked",
      text: t("DocumentGeneratedIn"),
      typeDocumentCode: TypeDocumentCodeEnum.SecurityCode,
    },
  ];

  return (
    <>
      <VehicleCheckedInContentWrapper>
        {downloadDocuments.map((m, i) => (
          <CardDownloadDocumentMolecule
            key={`download-doc-card-${i}`}
            iconName={m.icon}
            title={m.title}
            text={m.text}
            errorMsgModal={"ErrorMsgDownloadFilesOutOfStock"}
            onClickGetDocument={() =>
              getDocument(
                new GetDocumentRequest({
                  id: Number(params.id),
                  typeDocumentCode: m.typeDocumentCode,
                })
              )
            }
          />
        ))}
      </VehicleCheckedInContentWrapper>

      <ButtonWrapper>
        <ButtonTertiary
          sizex="md"
          onClick={() => {
            setMenuCurrentStepContext(RenaveMenuStepsEnum.CheckOutVehicle);
            history.push(paths.checkOutVehicle(params.id));
          }}
          disabled={props.viewMode}
        >
          {t("VehicleCheckout")}
        </ButtonTertiary>
      </ButtonWrapper>
    </>
  );
};
