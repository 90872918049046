import { ButtonSecondary, ButtonTertiary } from "c4u-web-components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CheckoutTypeEnum } from "../../../models";
import {
  SelectCheckoutWrapper,
  SelectCheckoutButtonText,
  SelectCheckoutButtonWrapper,
} from "./select-checkout-type.atom.style";

interface IProps {
  selectedButton: CheckoutTypeEnum | null;
  setSelectedButton: (v: CheckoutTypeEnum) => void;
  setGoClearForm: (v: boolean) => void;
  viewMode?: boolean;
}

export const SelectCheckoutButtonAtom: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const buttonTransferSelected = useMemo(
    () => props.selectedButton === CheckoutTypeEnum.TransferAuthorization,
    [props.selectedButton]
  );

  const buttonCheckoutSelected = useMemo(
    () => props.selectedButton === CheckoutTypeEnum.Exit,
    [props.selectedButton]
  );

  const ButtonTransfer = useMemo(
    () => (buttonTransferSelected ? ButtonTertiary : ButtonSecondary),
    [buttonTransferSelected]
  );

  const ButtonCheckout = useMemo(
    () => (buttonCheckoutSelected ? ButtonTertiary : ButtonSecondary),
    [buttonCheckoutSelected]
  );

  return (
    <SelectCheckoutWrapper>
      <SelectCheckoutButtonText>
        {t("OuOfStockSelectOptions")}
      </SelectCheckoutButtonText>

      <SelectCheckoutButtonWrapper>
        <ButtonTransfer
          sizex={"lg"}
          onClick={
            buttonTransferSelected
              ? undefined
              : () => {
                  props.setSelectedButton(
                    CheckoutTypeEnum.TransferAuthorization
                  );
                  props.setGoClearForm(true);
                }
          }
          disabled={props.viewMode}
        >
          {t("TransferToDealership")}
        </ButtonTransfer>

        <ButtonCheckout
          sizex={"lg"}
          onClick={
            buttonCheckoutSelected
              ? undefined
              : () => {
                  props.setSelectedButton(CheckoutTypeEnum.Exit);
                  props.setGoClearForm(true);
                }
          }
          disabled={props.viewMode}
        >
          {t("TransferToCustomer")}
        </ButtonCheckout>
      </SelectCheckoutButtonWrapper>
    </SelectCheckoutWrapper>
  );
};
