import {
  IKbbVehicle,
  VehicleCategoriesEnum,
  SourcePartnerEnum,
} from "c4u-web-components";
import React, { createContext, useState } from "react";
import { RenaveProviderState } from "../hooks/contexts/use-renave-context.hook";
import {
  ICheckInZeroKmModel,
  ICheckOutZeroKmModel,
  IDiagnosisRestriction,
  IDocumentModel,
  IGetRestrictionResponse,
  IUserAllInformation,
  IVehicleCheckoutModel,
  IVehicleDanfeForm,
  IVehicleEntryContext,
  PaymentStatusEnum,
  RenaveMenuStepsEnum,
  RenaveMenuStepsZeroKmEnum,
} from "../models";

export const RenaveContext = createContext({} as any);

export const RenaveProvider = (props: any) => {
  // General contexts
  const [kbbVehicleContext, setKbbVehicleContext] = useState<IKbbVehicle>();
  const [userContext, setUserContext] = useState<IUserAllInformation>();
  const [renaveVehicleFetchedContext, setRenaveVehicleFetchedContext] =
    useState<Boolean>();
  const [vehicleCategoryContext, setVehicleCategoryContext] =
    useState<VehicleCategoriesEnum>(VehicleCategoriesEnum.Car);
  const [sourcePartnerContext, setSourcePartnerContext] =
    useState<SourcePartnerEnum>(SourcePartnerEnum.Kbb);

  // Standard journey contexts
  const [restrictionContext, setRestrictionContext] =
    useState<IGetRestrictionResponse>();
  const [documentContext, setDocumentContext] = useState<IDocumentModel>();
  useState<IGetRestrictionResponse>();
  const [diagnosisListContext, setDiagnosisListContext] =
    useState<IDiagnosisRestriction[]>();
  const [vehicleEntryContext, setVehicleEntryContext] =
    useState<IVehicleEntryContext>();
  const [vehicleDanfeContext, setVehicleDanfeContext] =
    useState<IVehicleDanfeForm>();
  const [vehicleOutDanfeContext, setVehicleOutDanfeContext] =
    useState<IVehicleDanfeForm>();
  const [isEditingVehicleEntryContext, setIsEditingVehicleEntryContext] =
    useState(false);
  const [vehicleCheckoutContext, setVehicleCheckoutContext] =
    useState<IVehicleCheckoutModel>();
  const [zapayPaymentStatusContext, setZapayPaymentStatusContext] =
    useState<PaymentStatusEnum | null>();

  // Standard menu
  const [menuCurrentStepContext, setMenuCurrentStepContext] =
    useState<RenaveMenuStepsEnum>(RenaveMenuStepsEnum.Aptness);
  const [menuSelectedStepContext, setMenuSelectedStepContext] =
    useState<RenaveMenuStepsEnum>();

  // Zero km journey contexts
  const [zeroKmVehicleEntryContext, setZeroKmVehicleEntryContext] =
    useState<ICheckInZeroKmModel>();
  const [zeroKmVehicleCheckoutContext, setZeroKmVehicleCheckoutContext] =
    useState<ICheckOutZeroKmModel>();

  // Zero km menu
  const [menuCurrentStepZeroKmContext, setMenuCurrentStepZeroKmContext] =
    useState<RenaveMenuStepsZeroKmEnum>(
      RenaveMenuStepsZeroKmEnum.CheckInVehicleZeroKm
    );
  const [menuSelectedStepZeroKmContext, setMenuSelectedStepZeroKmContext] =
    useState<RenaveMenuStepsZeroKmEnum>();

  const changeVehicleCategoryContext = (data: VehicleCategoriesEnum) => {
    setVehicleCategoryContext(data);
    setZeroKmVehicleEntryContext(undefined);
  };

  return (
    <RenaveContext.Provider
      value={
        {
          kbbVehicleContext,
          setKbbVehicleContext,
          restrictionContext,
          setRestrictionContext,
          documentContext,
          setDocumentContext,
          diagnosisListContext,
          setDiagnosisListContext,
          vehicleEntryContext,
          setVehicleEntryContext,
          menuCurrentStepContext,
          setMenuCurrentStepContext,
          menuSelectedStepContext,
          setMenuSelectedStepContext,
          vehicleDanfeContext,
          setVehicleDanfeContext,
          vehicleOutDanfeContext,
          setVehicleOutDanfeContext,
          isEditingVehicleEntryContext,
          setIsEditingVehicleEntryContext,
          setUserContext,
          userContext,
          vehicleCheckoutContext,
          setVehicleCheckoutContext,
          zapayPaymentStatusContext,
          setZapayPaymentStatusContext,
          renaveVehicleFetchedContext,
          setRenaveVehicleFetchedContext,
          menuCurrentStepZeroKmContext,
          setMenuCurrentStepZeroKmContext,
          menuSelectedStepZeroKmContext,
          setMenuSelectedStepZeroKmContext,
          zeroKmVehicleEntryContext,
          setZeroKmVehicleEntryContext,
          zeroKmVehicleCheckoutContext,
          setZeroKmVehicleCheckoutContext,
          vehicleCategoryContext,
          setVehicleCategoryContext,
          changeVehicleCategoryContext,
          sourcePartnerContext,
          setSourcePartnerContext,
        } as RenaveProviderState
      }
    >
      {props.children}
    </RenaveContext.Provider>
  );
};
