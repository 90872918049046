import { MsgValidation, YupHelper } from "c4u-web-components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ISaveOutOfStockDealershipRequest } from "../../../models";

export const CheckOutDealershipFormMoleculeValidation = () => {
  const { t } = useTranslation();

  const msgRequired = t(MsgValidation.RequiredField);
  const msgInvalid = t(MsgValidation.Invalid);

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCpfCnpjValid",
    YupHelper.isCpfCnpjValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isDatePtBrValid",
    YupHelper.isDatePtBrValid
  );

  const validations = Yup.object<ISaveOutOfStockDealershipRequest>({
    id: Yup.string().required(msgRequired),
    businessNumber: Yup.string()
      .required(msgRequired)
      .isCpfCnpjValid(msgInvalid),
    email: Yup.string().required(msgRequired).email(t(MsgValidation.Email)),
    value: Yup.string().required(msgRequired),
    exitDate: Yup.string().isDatePtBrValid(msgInvalid).required(msgRequired),
  });

  return validations;
};
