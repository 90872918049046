import { ButtonSecondary, ButtonTertiary } from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths } from "../../../constants";
import { useRenave, useRenaveContext, useSessionContext } from "../../../hooks";
import {
  CheckoutTypeEnum,
  GetStatusRequest,
  IFormikSetErrors,
  IVehicleDanfeForm,
  PostExitCancellationRequest,
  RenaveMenuStepsEnum,
  RenaveStatusEnum,
  TypeDocumentCodeEnum,
  VehicleDanfeRequest,
} from "../../../models";
import {
  ContentCardMolecule,
  DownloadDocumentMolecule,
  NextStepsDanfeMolecule,
  VehicleDanfeFormMolecule,
} from "../../molecules";
import {
  ManageVehicleContentWrapper,
  ButtonWrapper,
} from "../shared-style/organisms.shared-style";

interface IProps {
  viewMode?: boolean;
}

export const CheckOutDanfeOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();
  const history = useHistory();
  const {
    vehicleEntryContext,
    vehicleOutDanfeContext,
    setVehicleOutDanfeContext,
    setMenuCurrentStepContext,
    setVehicleEntryContext,
    menuCurrentStepContext,
    vehicleCheckoutContext,
  } = useRenaveContext();
  const { postVehicleDanfe, postExitCancellation, getStatus } = useRenave();

  const {
    showGenericErrorModal,
    showGenericSuccessModal,
    handleFormikException,
  } = useSessionContext();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingExitCancellation, setIsSubmittingExitCancellation] =
    useState(false);
  const [goSubmit, setGoSubmit] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (!!vehicleEntryContext?.firstAcess)
      showGenericSuccessModal(t("RenaveExitTermMessage"), null, t("Success"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleEntryContext?.firstAcess]);

  useEffect(() => {
    const newVehicleEntryContext = { ...vehicleEntryContext };
    newVehicleEntryContext.firstAcess = false;
    setVehicleEntryContext({ ...newVehicleEntryContext });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setVehicleEntryContext]);

  const handleSubmitVehicleDanfeForm = useCallback(
    async (
      values: IVehicleDanfeForm,
      { setErrors }: IFormikSetErrors<IVehicleDanfeForm>
    ) => {
      setIsSubmitting(true);
      try {
        const data = await postVehicleDanfe(
          new VehicleDanfeRequest({
            ...values,
            id: Number(params.id),
          })
        );

        showGenericSuccessModal(
          "",
          null,
          t("InvoiceSendSuccessfully"),
          t("Danfe")
        );

        setVehicleOutDanfeContext({
          id: Number(params.id),
          name: data.name,
          invoiceDate: data.invoiceDate,
          invoiceKey: values.invoiceKey,
        });

        if (vehicleCheckoutContext.checkOutType === CheckoutTypeEnum.Exit) {
          setMenuCurrentStepContext(RenaveMenuStepsEnum.CheckedOut);
          history.push(paths.vehicleCheckedOut(params.id));
        } else {
          setIsEditing(true);
        }
      } catch (e: any) {
        handleFormikException(e, setErrors);
      } finally {
        setIsSubmitting(false);
        setGoSubmit(false);
      }
    },
    [
      handleFormikException,
      history,
      params.id,
      postVehicleDanfe,
      setMenuCurrentStepContext,
      setVehicleOutDanfeContext,
      showGenericSuccessModal,
      t,
      vehicleCheckoutContext?.checkOutType,
    ]
  );

  const handleExitCancellation = useCallback(
    async (id: number) => {
      try {
        setIsSubmittingExitCancellation(true);
        await postExitCancellation(new PostExitCancellationRequest({ id: id }));

        showGenericSuccessModal(
          "",
          () => history.push(paths.myStock()),
          t("CancelExitSuccessfully"),
          t("Danfe")
        );
      } catch (e) {
        showGenericErrorModal(t("CancelExitError"), null, "", t("Danfe"));
      } finally {
        setIsSubmittingExitCancellation(false);
      }
    },
    [
      postExitCancellation,
      showGenericSuccessModal,
      t,
      history,
      showGenericErrorModal,
    ]
  );

  const getStatusAsync = useCallback(async () => {
    if (
      !props.viewMode &&
      vehicleOutDanfeContext?.invoiceDate &&
      menuCurrentStepContext === RenaveMenuStepsEnum.CheckOutDanfe &&
      vehicleCheckoutContext.checkOutType ===
        CheckoutTypeEnum.TransferAuthorization
    ) {
      getStatus(
        new GetStatusRequest({
          id: Number(params.id),
        })
      )
        .then(async (data) => {
          if (data.step === RenaveStatusEnum.CheckedOut) {
            setMenuCurrentStepContext(RenaveMenuStepsEnum.CheckedOut);
            history.push(paths.vehicleCheckedOut(params.id));
          }
        })
        .catch((e) => {
          console.log("error get status", e);
        });
    }
  }, [
    props.viewMode,
    vehicleOutDanfeContext?.invoiceDate,
    menuCurrentStepContext,
    vehicleCheckoutContext?.checkOutType,
    getStatus,
    params.id,
    setMenuCurrentStepContext,
    history,
  ]);

  useEffect(() => {
    getStatusAsync();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      vehicleOutDanfeContext?.invoiceDate &&
      menuCurrentStepContext === RenaveMenuStepsEnum.CheckOutDanfe
    )
      setIsEditing(true);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ManageVehicleContentWrapper>
        <Row className="d-flex align-self-end">
          <Col>
            <DownloadDocumentMolecule
              id={Number(params.id)}
              title={t("RenaveExitLinkDownload")}
              typeDocument={TypeDocumentCodeEnum.ExitTerm}
              className="adjust-bottom-gap"
              inline
            />
          </Col>
        </Row>

        <ContentCardMolecule title={t("VehicleDanfe")}>
          <VehicleDanfeFormMolecule
            setGoSubmit={setGoSubmit}
            goSubmit={goSubmit}
            onSubmit={handleSubmitVehicleDanfeForm}
            setFormValid={setFormValid}
            initialValues={vehicleOutDanfeContext}
            vehicleDanfe={vehicleOutDanfeContext}
            editing={isEditing}
            viewMode={props.viewMode}
          />
        </ContentCardMolecule>

        {vehicleCheckoutContext.checkOutType !== CheckoutTypeEnum.Exit &&
          vehicleOutDanfeContext &&
          (isEditing || !!props.viewMode) && (
            <ContentCardMolecule title={t("NextSteps")}>
              <NextStepsDanfeMolecule
                plate={vehicleEntryContext?.plate}
                isCheckoutDanfe={true}
                isDealershipOutOfStock={
                  vehicleCheckoutContext.checkOutType ===
                  CheckoutTypeEnum.TransferAuthorization
                }
              />
            </ContentCardMolecule>
          )}
      </ManageVehicleContentWrapper>

      <ButtonWrapper>
        <ButtonSecondary
          sizex="md"
          loading={isSubmittingExitCancellation}
          onClick={() => {
            handleExitCancellation(Number(params.id));
          }}
          disabled={
            !!props.viewMode || isSubmitting || isSubmittingExitCancellation
          }
        >
          {t("CancelExit")}
        </ButtonSecondary>

        {!isEditing && !props.viewMode ? (
          <ButtonTertiary
            sizex="md"
            loading={isSubmitting}
            onClick={() => {
              setGoSubmit(true);
            }}
            disabled={!formValid}
          >
            {t("sendInvoiceKey")}
          </ButtonTertiary>
        ) : (
          <>
            <ButtonSecondary
              sizex="md"
              onClick={() => {
                setIsEditing(false);
              }}
              disabled={
                !vehicleOutDanfeContext ||
                props.viewMode ||
                isSubmittingExitCancellation
              }
            >
              {t("Edit")}
            </ButtonSecondary>
          </>
        )}
      </ButtonWrapper>
    </>
  );
};
