import { DateHelper, FormikControlAtom, MaskHelper } from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IVehicleDanfeForm } from "../../../models";
import { VehicleDanfeFormWrapper } from "./vehicle-danfe-form.molecule.style";
import { VehicleDanfeValidationSchema } from "./vehicle-danfe-form.molecule.validations";

interface IProps {
  setGoSubmit?: (v: boolean) => void;
  goSubmit?: boolean;
  onSubmit?: (values: any, errors: any) => Promise<void>;
  initialValues: IVehicleDanfeForm;
  setFormValid?: (v: boolean) => void;
  editing: boolean;
  vehicleDanfe: IVehicleDanfeForm;
  viewMode?: boolean;
}

export const VehicleDanfeFormMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const {
    setFormValid: setFormValidOnParent,
    editing: isEditing,
    vehicleDanfe,
    viewMode,
  } = props;

  const formik = useFormik<IVehicleDanfeForm>({
    initialValues: props.initialValues ?? ({} as IVehicleDanfeForm),
    onSubmit: async (values, { setErrors }) => {
      if (props.onSubmit) props.onSubmit(values, { setErrors });
    },
    validationSchema: VehicleDanfeValidationSchema(),
    validateOnBlur: true,
    validateOnChange: false,
  });

  useEffect(() => {
    if (Object.keys(formik.errors).length) {
      if (props.setGoSubmit) props.setGoSubmit(false);
    }
    // eslint-disable-next-line
  }, [formik.errors]);

  useEffect(() => {
    if (props.goSubmit) formik.submitForm();
    // eslint-disable-next-line
  }, [props.goSubmit]);

  useEffect(() => {
    if (setFormValidOnParent) {
      if (
        formik.values.invoiceKey &&
        formik.values.invoiceKey.trim().length === 44
      ) {
        setFormValidOnParent(true);
      } else {
        setFormValidOnParent(false);
      }
    }
  }, [formik.values, formik.errors, setFormValidOnParent]);

  useEffect(() => {
    if (!isEditing) {
      formik.setFieldValue("invoiceDate", "");
      formik.setFieldValue("name", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);

  useEffect(() => {
    if (vehicleDanfe?.invoiceDate && vehicleDanfe?.name) {
      formik.setFieldValue(
        "invoiceDate",
        DateHelper.dateHourFormat(vehicleDanfe.invoiceDate)
      );
      formik.setFieldValue("name", vehicleDanfe.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleDanfe]);

  return (
    <VehicleDanfeFormWrapper onSubmit={formik.handleSubmit}>
      <Form.Row>
        <FormikControlAtom
          label={t("invoiceKey")}
          property={"invoiceKey"}
          translate={t}
          formik={formik}
          mask={MaskHelper.DanfeNumber}
          placeholderChar={"\u2000"}
          disabled={!!vehicleDanfe?.invoiceKey && (isEditing || !!viewMode)}
        />
      </Form.Row>

      {vehicleDanfe && (isEditing || !!viewMode) && (
        <Form.Row
          className="d-flex align-items-baseline"
          style={{ gap: "24px" }}
        >
          <FormikControlAtom
            label={t("dateInvoiceKey")}
            property={"invoiceDate"}
            translate={t}
            formik={formik}
            disabled
          />
          <FormikControlAtom
            label={t("userInvoiceKey")}
            property={"name"}
            translate={t}
            formik={formik}
            disabled
          />
        </Form.Row>
      )}
    </VehicleDanfeFormWrapper>
  );
};
