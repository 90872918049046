import React from "react";
import { PageControlItem } from "./pagination-button.atom.style";

interface IProps {
  onClick?: () => void;
  title?: string;
  symbol: string | number | React.ReactNode;
  selected?: boolean;
  disabled?: boolean;
}

export const PaginationButtonAtom: React.FC<IProps> = (props) => {
  return (
    <PageControlItem
      className={props.selected ? "selected" : ""}
      title={props.title}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.symbol}
    </PageControlItem>
  );
};
