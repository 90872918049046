import { Combo, theme, Translator } from "c4u-web-components";
import { paths } from "../constants";
import {
  RenaveMenuStepsEnum,
  RenaveMenuStepsZeroKmEnum,
  RenaveStatusEnum,
} from "../models";

export class RenaveStatusHelper {
  static getMenuStepFromStatus(status: RenaveStatusEnum) {
    switch (status) {
      case RenaveStatusEnum.CheckedOut:
        return RenaveMenuStepsEnum.CheckedOut;
      case RenaveStatusEnum.CheckOutDanfe:
      case RenaveStatusEnum.CheckOutVehicle:
        return RenaveMenuStepsEnum.CheckOutDanfe;
      case RenaveStatusEnum.CheckedIn:
        return RenaveMenuStepsEnum.CheckedIn;
      case RenaveStatusEnum.CheckInDanfe:
      case RenaveStatusEnum.CheckInVehicle:
        return RenaveMenuStepsEnum.CheckInDanfe;
      case RenaveStatusEnum.Aptness:
        return RenaveMenuStepsEnum.CheckInVehicle;
      case RenaveStatusEnum.Created:
        return RenaveMenuStepsEnum.Aptness;
      case RenaveStatusEnum.Cancelled:
      default:
        return null;
    }
  }

  static getZeroKmMenuStepFromStatus(status: RenaveStatusEnum) {
    switch (status) {
      case RenaveStatusEnum.CheckedInZeroKm:
        return RenaveMenuStepsZeroKmEnum.CheckedInZeroKm;
      case RenaveStatusEnum.CheckedOutZeroKm:
        return RenaveMenuStepsZeroKmEnum.CheckedOutZeroKm;
      default:
        return null;
    }
  }

  static getStatusColor(status: RenaveStatusEnum) {
    switch (status) {
      case RenaveStatusEnum.CheckedOut:
      case RenaveStatusEnum.CheckedIn:
      case RenaveStatusEnum.CheckedInZeroKm:
      case RenaveStatusEnum.CheckedOutZeroKm:
        return theme.colors.Confirm;
      case RenaveStatusEnum.Aptness:
        return theme.colors.Auth;
      case RenaveStatusEnum.Created:
      case RenaveStatusEnum.Cancelled:
      case RenaveStatusEnum.RejectedZeroKm:
        return theme.colors.Error;
      case RenaveStatusEnum.CheckOutDanfe:
      case RenaveStatusEnum.CheckOutVehicle:
      case RenaveStatusEnum.CheckInDanfe:
      case RenaveStatusEnum.CheckInVehicle:
      case RenaveStatusEnum.CheckOutVehicleZeroKm:
      case RenaveStatusEnum.PendingZeroKm:
      default:
        return theme.colors.Warning;
    }
  }

  static getStatusName(status: RenaveStatusEnum, t: Translator) {
    switch (status) {
      case RenaveStatusEnum.Created:
        return t("AptnessWithRestriction");
      case RenaveStatusEnum.Aptness:
        return t("CheckInVehicle");
      case RenaveStatusEnum.CheckInVehicle:
        return t("CheckInDanfe");
      case RenaveStatusEnum.CheckInDanfe:
        return t("WaitingCheckInDanfeConfirmation");
      case RenaveStatusEnum.CheckedIn:
        return t("CheckedIn");
      case RenaveStatusEnum.CheckOutVehicle:
        return t("CheckOutDanfe");
      case RenaveStatusEnum.CheckOutDanfe:
        return t("WaitingCheckOutDanfeConfirmation");
      case RenaveStatusEnum.CheckedOut:
        return t("CheckedOut");
      case RenaveStatusEnum.Cancelled:
        return t("CheckInCancelled");
      case RenaveStatusEnum.PendingZeroKm:
        return t("CheckInPendingZeroKm");
      case RenaveStatusEnum.RejectedZeroKm:
        return t("RejectedZeroKm");
      case RenaveStatusEnum.CheckedInZeroKm:
        return t("CheckedInZeroKm");
      case RenaveStatusEnum.CheckOutVehicleZeroKm:
        return t("CheckOutVehicleZeroKm");
      case RenaveStatusEnum.CheckedOutZeroKm:
        return t("CheckedOutZeroKm");
      case RenaveStatusEnum.ReturnedZeroKm:
        return t("ReturnedZeroKm");
      default:
        return t("");
    }
  }

  static getStatusCombos(t: Translator) {
    return Object.entries(RenaveStatusEnum)
      .filter(([k, _]) => !isNaN(parseInt(k)))
      .map(
        ([k, _]) =>
          new Combo({
            title: this.getStatusName(parseInt(k), t),
            value: k,
          })
      );
  }

  static getOnClickAccessPath(
    menuStep: RenaveMenuStepsEnum
  ): (id: string | number | null) => string {
    switch (menuStep) {
      case RenaveMenuStepsEnum.CheckedOut:
        return paths.vehicleCheckedOut;
      case RenaveMenuStepsEnum.CheckOutDanfe:
        return paths.checkOutDanfe;
      case RenaveMenuStepsEnum.CheckOutVehicle:
        return paths.checkOutVehicle;
      case RenaveMenuStepsEnum.CheckedIn:
        return paths.vehicleCheckedIn;
      case RenaveMenuStepsEnum.CheckInDanfe:
        return paths.checkInDanfe;
      case RenaveMenuStepsEnum.CheckInVehicle:
        return paths.checkInVehicle;
      case RenaveMenuStepsEnum.Aptness:
        return paths.checkInAptness;
    }
  }
  static getZeroKmOnClickAccessPath(
    menuStep: RenaveMenuStepsZeroKmEnum
  ): (id: string | number | null) => string {
    switch (menuStep) {
      case RenaveMenuStepsZeroKmEnum.CheckedOutZeroKm:
        return paths.vehicleCheckedOutZeroKm;
      case RenaveMenuStepsZeroKmEnum.CheckOutVehicleZeroKm:
        return paths.checkOutZeroKm;
      case RenaveMenuStepsZeroKmEnum.CheckedInZeroKm:
        return paths.vehicleCheckedInZeroKm;
      case RenaveMenuStepsZeroKmEnum.CheckInVehicleZeroKm:
        return paths.checkInZeroKm;
    }
  }
}
