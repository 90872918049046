import { CoxIcon } from "c4u-web-components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "../../../constants";
import { IDiagnosisRestriction } from "../../../models";
import {
  RestrictionList,
  ListItem,
  ListItemDescription,
  ListItemDescriptionLink,
} from "./restrictions-list.molecule.style";

interface IProps {
  diagnosis: IDiagnosisRestriction[];
  disabilityReason?: string[];
  notEnoughBalance?: boolean;
}

export const RestrictionListMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { diagnosis, disabilityReason, notEnoughBalance } = props;

  return (
    <RestrictionList>
      {disabilityReason?.map((m, i) => (
        <ListItem key={`unsuitability-reason-${i}`}>
          <CoxIcon name="danger-color" width={16} height={14} />
          <ListItemDescription>
            {m.trim().replace(/[.!]+$/g, "")}
          </ListItemDescription>
        </ListItem>
      ))}

      {notEnoughBalance !== undefined && (
        <ListItem>
          {notEnoughBalance ? (
            <>
              <CoxIcon name="danger-color" width={16} height={14} />
              <ListItemDescription>
                {t("Not enough balance")}{" "}
                <ListItemDescriptionLink
                  onClick={() =>
                    history.push(paths.CreditAcquisitionPacksPage())
                  }
                >
                  {t("Buy here")}
                </ListItemDescriptionLink>
              </ListItemDescription>
            </>
          ) : (
            <>
              <CoxIcon name="checked" width={16} height={16} />
              <ListItemDescription>{t("Enough Balance")}</ListItemDescription>
            </>
          )}
        </ListItem>
      )}

      {diagnosis?.map((m, i) => (
        <ListItem key={`diagnosis-item-${i}`}>
          {!m.value ? (
            <CoxIcon name="checked" width={16} height={16} />
          ) : (
            <CoxIcon name="danger-color" width={16} height={14} />
          )}

          <ListItemDescription>{t(m.key)}</ListItemDescription>
        </ListItem>
      ))}
    </RestrictionList>
  );
};
