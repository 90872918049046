import styled from "styled-components";

const CreditStatementTextStyleBase = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
`;

export const CreditStatementBalance = styled(CreditStatementTextStyleBase)`
  color: ${(props) => props.theme.colors.Dark};
  font-size: 14px;
  line-height: 14px;
`;

export const CreditStatementBalanceValue = styled(CreditStatementTextStyleBase)`
  color: ${(props) => props.theme.colors.BlueHigh};
  font-size: 14px;
  line-height: 14px;
`;

export const CreditStatementPaginationWrapper = styled.div`
  margin-top: 40px;
`;

const BaseFlexWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CreditSummaryWrapper = styled(BaseFlexWrapper)`
  flex-direction: column;
  gap: 10px;
`;

export const BalanceWrapper = styled(BaseFlexWrapper)``;

export const CreditStatementWrapper = styled.div``;
