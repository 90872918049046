import {
  ButtonPrimary,
  ButtonSecondary,
  MsgValidation,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSessionContext } from "../../../../hooks";
import {
  ConflictRequestError,
  IPostCancelRejectionZeroKmRequest,
  IPostRejectZeroKmRequest,
  PostCancelRejectionZeroKmRequest,
} from "../../../../models";
import { FeedbackModal } from "../../feedback-modal/feedback-modal.molecule";
import { CancelRejectionFormButtonsGroup } from "./cancel-rejection-modal.molecule.style";

interface IProps {
  id: number | undefined;
  setId: (v: number | undefined) => void;
  cancelRejectionCallback: (
    r: PostCancelRejectionZeroKmRequest
  ) => Promise<void>;
  onCancelRejectionSuccessful: () => void;
}

export const CancelRejectionModalMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { handleFormikException, showGenericErrorModal } = useSessionContext();

  const msgRequired = t(MsgValidation.RequiredField);

  const validations = Yup.object<IPostCancelRejectionZeroKmRequest>({
    id: Yup.number().required(msgRequired),
  });

  const formik = useFormik<IPostCancelRejectionZeroKmRequest>({
    initialValues: { id: props.id } as IPostRejectZeroKmRequest,
    onSubmit: async (values, { setErrors }) => {
      try {
        await props.cancelRejectionCallback(
          new PostCancelRejectionZeroKmRequest(values)
        );
        props.onCancelRejectionSuccessful();
      } catch (e: any) {
        if (e instanceof ConflictRequestError) {
          handleFormikException(e, setErrors);
        } else {
          showGenericErrorModal(t("GenericErrorMsg"), onHide, t("Error"));
        }
      } finally {
        onHide();
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  const onHide = () => {
    props.setId(undefined);
    formik.resetForm();
  };

  useEffect(() => {
    if (props.id) formik.setFieldValue("id", props.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  return (
    <FeedbackModal
      show={props.id !== undefined}
      message={t("Confirm cancel rejection message")}
      titleHeader={t("Cancel Rejection Modal Title")}
      onHide={onHide}
      typeIcon="none"
      size="md"
      hideFooter
      reducePaddingTop
    >
      <Form onSubmit={formik.handleSubmit}>
        <Form.Row>
          <CancelRejectionFormButtonsGroup>
            <ButtonSecondary onClick={onHide} sizex="md">
              {t("No")}
            </ButtonSecondary>
            <ButtonPrimary
              type="submit"
              sizex="md"
              loading={formik.isSubmitting}
            >
              {t("Yes")}
            </ButtonPrimary>
          </CancelRejectionFormButtonsGroup>
        </Form.Row>
      </Form>
    </FeedbackModal>
  );
};
