import { MsgValidation, YupHelper } from "c4u-web-components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { IPostDealershipCheckOutZeroKmRequest } from "../../../models";

export const ZeroKmDealershipCheckoutFormValidation = () => {
  const { t } = useTranslation();

  const msgRequired = t(MsgValidation.RequiredField);

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCnpjValid",
    YupHelper.isCnpjValid
  );

  const validations = Yup.object<IPostDealershipCheckOutZeroKmRequest>({
    id: Yup.number().required(msgRequired),
    buyerDocument: Yup.string()
      .required(msgRequired)
      .isCnpjValid(t(MsgValidation.Cnpj)),
    saleValue: Yup.number().required(msgRequired),
  });

  return validations;
};
