import styled from "styled-components";
import { Image } from "react-bootstrap";

export const PaginationControl = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 18px;
`;

export const PageNumbers = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
`;

export const MirroredImage = styled(Image)`
  transform: scale(-1);
`;
