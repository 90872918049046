import { TypesHelper } from "c4u-web-components";

export interface IGetCreditStatementRequest {
  startDate: Date;
  finalDate: Date;
  plate?: string;
}

export class GetCreditStatementRequest {
  startDate: Date | null;
  finalDate: Date | null;
  plate: string | null;

  constructor({ startDate, finalDate, plate }: IGetCreditStatementRequest) {
    this.startDate = TypesHelper.toDateTimeNull(startDate);
    this.finalDate = TypesHelper.toDateTimeNull(finalDate);
    this.plate = TypesHelper.toStringNull(plate);
  }
}
