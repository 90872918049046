import { MaskHelper, MsgValidation, YupHelper } from "c4u-web-components";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { ICreditAcquisitionForm } from "../../../models";
import { FormikControlRenaveAtom } from "../../atoms";
import {
  CreditAcquisitionStyledForm,
  CreditAcquisitionTextTitle,
} from "./credit-acquisition-form.molecule.style";

interface IProps {
  goSubmit?: boolean;
  onSubmit?: (values: any, errors: any) => Promise<void>;
  setIsSubmitting?: (v: boolean) => void;
}

export const CreditAcquisitionFormMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const initialValues: ICreditAcquisitionForm = {
    expiration: "",
    cardholder: "",
    cardNumber: "",
    cvv: "",
  };

  const msgRequired = t(MsgValidation.RequiredField);

  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isCreditCardValid",
    YupHelper.isCreditCardValid
  );
  Yup.addMethod<Yup.StringSchema>(
    Yup.string,
    "isValidByRegex",
    YupHelper.isValidByRegex
  );

  const validations = Yup.object<ICreditAcquisitionForm>({
    cardholder: Yup.string()
      .required(msgRequired)
      .max(100, t(MsgValidation.Cvv)),
    cardNumber: Yup.string()
      .required(msgRequired)
      .isCreditCardValid(t(MsgValidation.CreditCard)),
    expiration: Yup.string()
      .required(msgRequired)
      .isValidByRegex(/(0[1-9]|1[0-2])\/\d\d/, t(MsgValidation.Date)),
    cvv: Yup.string()
      .required(msgRequired)
      .isValidByRegex(/\d{3}/, t(MsgValidation.Cvv)),
  });

  const formik = useFormik<ICreditAcquisitionForm>({
    initialValues: initialValues,
    onSubmit: async (values, { setErrors }) => {
      if (props.onSubmit) await props.onSubmit(values, { setErrors });
    },
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: validations,
  });

  useEffect(() => {
    if (props.goSubmit) formik.submitForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.goSubmit]);

  useEffect(() => {
    if (props.setIsSubmitting) props.setIsSubmitting(formik.isSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.isSubmitting]);

  return (
    <CreditAcquisitionStyledForm onSubmit={formik.handleSubmit}>
      <Row className="mb-5 mt-4">
        <Col>
          <CreditAcquisitionTextTitle>
            {t("EnterPaymentData")}
          </CreditAcquisitionTextTitle>
        </Col>
      </Row>

      <Form.Row className="d-flex align-items-baseline">
        <FormikControlRenaveAtom
          formik={formik}
          property={"cardholder"}
          label={t("CreditCardName")}
          md={4}
          maxLength={100}
        />
      </Form.Row>
      <Form.Row className="d-flex align-items-baseline">
        <FormikControlRenaveAtom
          formik={formik}
          property={"cardNumber"}
          label={t("CreditCardNumber")}
          md={3}
          mask={MaskHelper.CreditCard}
          placeholderChar={"\u2000"}
        />

        <FormikControlRenaveAtom
          formik={formik}
          property={"expiration"}
          label={t("CreditCardExpirationDate")}
          md={2}
          mask={MaskHelper.CreditCardValidity}
          placeholder="MM/AA"
        />

        <FormikControlRenaveAtom
          formik={formik}
          property={"cvv"}
          label={t("CreditCardCVV")}
          md={2}
          mask={MaskHelper.CreditCardCVV}
        />
      </Form.Row>
    </CreditAcquisitionStyledForm>
  );
};
