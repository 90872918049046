import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { paths } from "../../../constants";
import { useRenaveContext } from "../../../hooks";
import {
  IGetVehicleResponse,
  IPagedResponseBase,
  RenaveMenuStepsEnum,
} from "../../../models";
import { HeaderTitleMolecule } from "../../molecules";
import {
  BalanceAndCreditsOrganism,
  MyStockHeaderOrganism,
  MyStockListOrganism,
} from "../../organisms";
import { GradientHR } from "../shared/shared.page.style";

export const MyStockPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    setMenuSelectedStepContext,
    setMenuCurrentStepContext,
    setRestrictionContext,
    setKbbVehicleContext,
    setVehicleEntryContext,
    setVehicleDanfeContext,
    setVehicleCheckoutContext,
    setDiagnosisListContext,
    setDocumentContext,
    setZapayPaymentStatusContext,
    setRenaveVehicleFetchedContext,
  } = useRenaveContext();

  const [myVehicles, setMyVehicles] = useState<IGetVehicleResponse[]>();
  const [paginationData, setPaginationData] = useState<IPagedResponseBase>();
  const [pageNumber, setPageNumber] = useState<number>();
  const [goUpdate, setGoUpdate] = useState(false);

  useEffect(() => {
    if (goUpdate) setGoUpdate(false);
  }, [goUpdate]);

  useEffect(() => {
    setMenuSelectedStepContext(undefined);
    setMenuCurrentStepContext(undefined);
    setRestrictionContext(undefined);
    setKbbVehicleContext(undefined);
    setVehicleEntryContext(undefined);
    setVehicleDanfeContext(undefined);
    setVehicleCheckoutContext(undefined);
    setDiagnosisListContext(undefined);
    setDocumentContext(undefined);
    setZapayPaymentStatusContext(undefined);
    setRenaveVehicleFetchedContext(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeaderTitleMolecule
        title={t("MyStockPageTitle")}
        navigationButtonText={t("AddNewVehicle")}
        navigationButtonAction={() => {
          setMenuCurrentStepContext(RenaveMenuStepsEnum.Aptness);
          history.push(paths.aptnessQuery());
        }}
        disableReturnButton
      />

      <MyStockHeaderOrganism
        setMyVehicles={setMyVehicles}
        setPaginationData={setPaginationData}
        pageNumber={pageNumber}
        goUpdate={goUpdate}
        setGoUpdate={setGoUpdate}
      />

      <GradientHR />

      <BalanceAndCreditsOrganism goUpdate={goUpdate} />

      <MyStockListOrganism
        myVehicles={myVehicles}
        paginationData={paginationData}
        setPageNumber={setPageNumber}
        setGoUpdate={setGoUpdate}
      />
    </>
  );
};
