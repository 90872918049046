import { CoxIcon, theme } from "c4u-web-components";
import React from "react";
import {
  OnScreenMsgWrapper,
  OnScreenMsgText,
} from "./on-screen-msg.atom.style";

interface IProps {
  text: string | React.ReactElement;
  type: "warning" | "success";
  iconName?: string;
  iconColor?: string;
  id?: string;
}

export const OnScreenMessageAtom: React.FC<IProps> = (props) => {
  const borderColor =
    props.iconColor ??
    (props.type === "warning" ? theme.colors.Warning : theme.colors.Confirm);

  const iconName =
    props.iconName ??
    (props.type === "warning" ? "warning-color" : "approved-color");

  return (
    <OnScreenMsgWrapper borderColor={borderColor} id={props.id}>
      <div>
        <CoxIcon
          name={iconName}
          fillColor={props.iconColor ?? undefined}
          width={20}
          height={20}
        />
      </div>
      <OnScreenMsgText>{props.text}</OnScreenMsgText>
    </OnScreenMsgWrapper>
  );
};
