import { TypesHelper } from "c4u-web-components";
import { TypeSignatureEnum, TypeCrvEnum } from "../../index";

export interface IUploadDocumentRequest {
  file: Blob | null;
  plate: string;
  renavam: string;
  numberCrv: string;
  typeCrv?: TypeCrvEnum;
  typeSignature?: TypeSignatureEnum;
}

export class UploadDocumentRequest {
  file: Blob | null;
  plate: string | null;
  renavam: string | null;
  numberCrv: string | null;
  typeCrv?: TypeCrvEnum | null;
  typeSignature?: TypeSignatureEnum | null;

  constructor({
    file,
    plate,
    renavam,
    numberCrv,
    typeCrv,
    typeSignature,
  }: IUploadDocumentRequest) {
    this.file = file;
    this.plate = TypesHelper.toStringNull(plate.replace("-", ""));
    this.renavam = TypesHelper.toStringOnlyNumbersNull(renavam);
    this.numberCrv = TypesHelper.toStringOnlyNumbersNull(numberCrv);
    this.typeCrv = TypesHelper.toNumberNull(typeCrv);
    this.typeSignature = TypesHelper.toNumberNull(typeSignature);
  }
}
