import { Row } from "react-bootstrap";
import styled from "styled-components";

export const WrapperRenaveRegister = styled.div`
  max-width: 992px;
  margin: 0 auto;
`;

export const WrapperRenaveInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 29px;
`;

export const HeaderInformation = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  background: #ededee;

  padding: 12px 65px 17px;

  min-height: 114px;
  margin: 0 auto;

  border-radius: 13px;

  @media (max-width: 575px) {
    padding: 12px 9px 17px;
  }
`;

export const TitleInformation = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;

  letter-spacing: -0.15px;

  color: ${(props) => props.theme.colors.BlueNavy};
`;

export const DescriptionInformation = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;

  color: ${(props) => props.theme.colors.BlueNavy};
`;

export const WrapperCardInfo = styled(Row)`
  @media (max-width: 993px) {
    gap: 25px;
  }
`;
