export const zapayCoverageStates: string[] = [
  "AC",
  "AL",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RR",
  "SC",
  "SP",
  "TO",

  // ESTADO "BR" TEMPORÁRIO PARA EVITAR BLOQUEIO DE FLUXO.
  // MOTIVO: O SERVIÇO DO RENAVE EM HOMOLOGAÇÃO TRAZ TODAS AS PLACAS COM A UF "BR"

  "BR",
];
