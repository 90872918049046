import {
  ButtonPrimary,
  FormikControlAtom,
  FormikControlCalendarAtom,
  MaskHelper,
  MsgValidation,
} from "c4u-web-components";
import { addDays, differenceInDays } from "date-fns";
import { useFormik } from "formik";
import React, { useEffect, useMemo } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useSessionContext } from "../../../hooks";
import {
  GetCreditStatementRequest,
  IGetCreditStatementRequest,
  IGetCreditStatementResponse,
} from "../../../models";

interface IProps {
  getCreditStatement: (
    request: GetCreditStatementRequest
  ) => Promise<IGetCreditStatementResponse[]>;
  setCreditStatement: (v?: IGetCreditStatementResponse[]) => void;
  onStartSearch: () => void;
}

export const CreditStatementSearchFormMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { showGenericErrorModal } = useSessionContext();

  const initialValues = useMemo<IGetCreditStatementRequest>(() => {
    const dateNow = new Date();

    return {
      startDate: addDays(dateNow, -90),
      finalDate: dateNow,
      plate: "",
    };
  }, []);

  const msgRequired = t(MsgValidation.RequiredField);

  const validations = Yup.object<IGetCreditStatementRequest>({
    plate: Yup.string().optional(),
    startDate: Yup.date().required(msgRequired),
    finalDate: Yup.date()
      .required(msgRequired)
      .test("date-start-small-date-final", t("ValidationDate"), function (end) {
        if (!end) return true;
        const start = this.resolve(Yup.ref("startDate"));
        const diffDays = differenceInDays(end, start);

        return diffDays >= 0;
      })
      .test("period-valid", t("ValidationIntervalDates"), function (end) {
        if (!end) return true;
        const start = this.resolve(Yup.ref("startDate"));
        const diffDays = differenceInDays(end, start);

        return diffDays <= 90;
      }),
  });

  const formik = useFormik<IGetCreditStatementRequest>({
    initialValues: initialValues,
    onSubmit: async (values, { setErrors }) => {
      try {
        props.onStartSearch();
        const response = await props.getCreditStatement(
          new GetCreditStatementRequest(values)
        );
        props.setCreditStatement(response);
      } catch (e) {
        props.setCreditStatement([]);
        showGenericErrorModal(
          t("ErrorMsgGetCreditStatement"),
          null,
          t("Error")
        );
      }
    },
    validationSchema: validations,
    validateOnBlur: true,
    validateOnChange: false,
  });

  useEffect(() => {
    formik.submitForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fieldProps = useMemo(() => {
    return {
      formik,
      translate: t,
      xs: 6,
      sm: 4,
      md: 3,
    };
  }, [formik, t]);

  return (
    <Form className="mt-4" onSubmit={formik.handleSubmit}>
      <Form.Row>
        <FormikControlCalendarAtom
          {...fieldProps}
          property="startDate"
          label={t("StartDate")}
        />
        <FormikControlCalendarAtom
          {...fieldProps}
          property="finalDate"
          label={t("FinalDate")}
        />

        <FormikControlAtom
          {...fieldProps}
          xs={8}
          sm={5}
          md={4}
          property={"plate"}
          label={t("PlateAndChassi")}
          mask={MaskHelper.PlateBrChassi}
          placeholderChar={"\u2000"}
          func={(v: string) => v.toUpperCase()}
        />

        <Form.Group
          as={Col}
          xs={4}
          sm={3}
          md={2}
          className="pt-4 mt-3 text-center"
        >
          <ButtonPrimary
            type="submit"
            sizex="sm"
            sizey="thin"
            loading={formik.isSubmitting}
          >
            {t("OK")}
          </ButtonPrimary>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
