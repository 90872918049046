import { Form } from "react-bootstrap";
import styled from "styled-components";

export const ReturnModalFormRow = styled(Form.Row)`
  width: 360px;
  .col, .col-12 {
    padding: 0;
  }
  label {
    font-weight: 700;
  }
`;

export const ReturnFormButtonsGroup = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  margin: 30px 0 0;
`;
