import styled from "styled-components";

export const MyStockHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 21px;

  margin: 20px 0;
`;

export const MenuIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 28px;
`;

export const CleanFiltersDiv = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  text-transform: uppercase;
  color: ${(props) => props.theme.colors.BlueNavy};

  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
  opacity: ${(props) => (props.onClick ? 1 : 0.4)};

  :hover {
    text-decoration-line: underline;
  }
`;

export const ClickableDiv = styled.div`
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};
`;

export const RotatableClickableDiv = styled(ClickableDiv)<{
  isLoading?: boolean;
}>`
  animation: rotate linear 1s ${(props) => (props.isLoading ? "infinite" : "0")};

  @keyframes rotate {
    0% {
      transform: rotate(0deg) translate(0, -0.78px);
    }
    100% {
      transform: rotate(360deg) translate(0, -0.78px);
    }
  }
`;
