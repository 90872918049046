import React, { createContext, useState } from "react";
import { SessionProviderState } from "../hooks/contexts/use-session-context.hook";
import { ConflictRequestError } from "../models";

const SessionContext = createContext({} as any);

const SessionProvider = (props: any) => {
  const [clickButtonGenericErrorModal, setClickButtonGenericErrorModal] =
    useState<(() => void) | null>(null);
  const [clickButtonGenericWarningModal, setClickButtonGenericWarningModal] =
    useState<(() => void) | null>(null);
  const [clickButtonGenericSuccessModal, setClickButtonGenericSuccessModal] =
    useState<(() => void) | null>(null);
  const [genericErrorModal, setShowGenericErrorModal] = useState(false);
  const [titleErrorModal, setTitleErrorModal] = useState<string | null>(null);
  const [textHeaderErrorModal, setTextHeaderErrorModal] = useState<
    string | null
  >(null);

  const [genericWarningModal, setShowGenericWarningModal] = useState(false);
  const [titleWarningModal, setTitleWarningModal] = useState<string | null>(
    null
  );

  const [genericSuccessModal, setShowGenericSuccessModal] = useState(false);
  const [titleSuccessModal, setTitleSuccessModal] = useState<string | null>(
    null
  );
  const [textHeaderSucessModal, setTextHeaderSucessModal] = useState<
    string | null
  >(null);

  const [genericModalMsg, setGenericModalMsg] = useState<string | null>(null);
  const [genericEventClose, setGenericEventClose] = useState<
    (() => void) | null
  >(null);

  const showGenericErrorModal = (
    text: string | null = null,
    onClose: (() => void) | null = null,
    title: string | null = null,
    textHeader: string | null = null
  ) => {
    setGenericModalMsg(text);
    setTitleErrorModal(title);
    setTextHeaderErrorModal(textHeader);
    setShowGenericErrorModal(true);
    if (onClose) setGenericEventClose(() => onClose);
  };
  const hideGenericErrorModal = () => {
    setGenericModalMsg(null);
    setTitleErrorModal(null);
    setTextHeaderErrorModal(null);
    setShowGenericErrorModal(false);

    if (genericEventClose) {
      genericEventClose();
      setGenericEventClose(null);
    }
  };
  const onClickButtonGenericErrorModal = (onClickButtonParam: () => void) => {
    setClickButtonGenericErrorModal(() => () => {
      onClickButtonParam();
      setGenericModalMsg(null);
      setShowGenericErrorModal(false);
    });
  };

  const showGenericWarningModal = (
    text: string | null = null,
    onClose: (() => void) | null = null,
    title: string | null = null
  ) => {
    setGenericModalMsg(text);
    setTitleWarningModal(title);
    setShowGenericWarningModal(true);
    if (onClose) setGenericEventClose(() => onClose);
  };
  const hideGenericWarningModal = () => {
    setGenericModalMsg(null);
    setTitleWarningModal(null);
    setShowGenericWarningModal(false);
    if (genericEventClose) {
      genericEventClose();
      setGenericEventClose(null);
    }
  };
  const onClickButtonGenericWarningModal = (onClickButtonParam: () => void) => {
    setClickButtonGenericWarningModal(() => () => {
      onClickButtonParam();
      setGenericModalMsg(null);
      setShowGenericWarningModal(false);
    });
  };

  const showGenericSuccessModal = (
    text: string | null = null,
    onClose: (() => void) | null = null,
    title: string | null = null,
    textHeader: string | null = null
  ) => {
    setGenericModalMsg(text);
    setTitleSuccessModal(title);
    setTextHeaderSucessModal(textHeader);
    setShowGenericSuccessModal(true);
    if (onClose) setGenericEventClose(() => onClose);
  };
  const hideGenericSuccessModal = () => {
    setGenericModalMsg(null);
    setTitleSuccessModal(null);
    setTextHeaderSucessModal(null);
    setShowGenericSuccessModal(false);
    if (genericEventClose) {
      genericEventClose();
      setGenericEventClose(null);
    }
  };
  const onClickButtonGenericSuccessModal = (onClickButtonParam: () => void) => {
    setClickButtonGenericSuccessModal(() => () => {
      onClickButtonParam();
      setGenericModalMsg(null);
      setShowGenericSuccessModal(false);
    });
  };

  const handleFormikError = (exception: any, setErrors?: any, msg?: string) => {
    if (exception?.errors) {
      showGenericWarningModal();
      setErrors(exception.errors);
    } else if (exception instanceof ConflictRequestError || exception?.message)
      return exception?.message.toString();
    else {
      if (msg) setGenericModalMsg(msg);
      setShowGenericErrorModal(true);
    }
  };

  const handleFormikException = (
    exception: any,
    setErrors?: any,
    msg?: string
  ) => {
    const message = handleFormikError(exception, setErrors, msg);
    if (message) showGenericWarningModal(message);
  };

  return (
    <SessionContext.Provider
      value={
        {
          genericModalMsg,
          genericErrorModal,
          showGenericErrorModal,
          hideGenericErrorModal,
          genericWarningModal,
          onClickButtonGenericErrorModal,
          onClickButtonGenericWarningModal,
          onClickButtonGenericSuccessModal,
          showGenericWarningModal,
          hideGenericWarningModal,
          genericSuccessModal,
          showGenericSuccessModal,
          hideGenericSuccessModal,
          handleFormikError,
          handleFormikException,
          titleErrorModal,
          textHeaderErrorModal,
          titleWarningModal,
          titleSuccessModal,
          textHeaderSucessModal,
          clickButtonGenericErrorModal,
          clickButtonGenericWarningModal,
          clickButtonGenericSuccessModal,
        } as SessionProviderState
      }
    >
      {props.children}
    </SessionContext.Provider>
  );
};

export { SessionContext, SessionProvider };
