import React from "react";
import {
  NextStepsWrapper,
  InformativeSection,
  ListItensSection,
} from "./next-steps-danfe.molecule.style";
import { useTranslation } from "react-i18next";

interface IProps {
  plate: string;
  isCheckoutDanfe?: boolean;
  isDealershipOutOfStock?: boolean;
}

export const NextStepsDanfeMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  return (
    <NextStepsWrapper>
      {props.isCheckoutDanfe && props.isDealershipOutOfStock ? (
        <>
          <InformativeSection>
            <div>{t("NextStepDealershipOutOfStock")}</div>
          </InformativeSection>
        </>
      ) : (
        <>
          <InformativeSection>
            <div>{t("DeliveryDocumentsDETRAN")}</div>
            <div>{`${t("Vehicle")} ${props.plate ?? ""}`}</div>
          </InformativeSection>

          <ListItensSection>
            <div>{t("ListDocumentsDETRAN")}</div>
            <ul>
              <li>{t("ATPV")}</li>
              <li>{t("InvoiceEntry")}</li>
              <li>{t("StockEntryTerm")}</li>
            </ul>
          </ListItensSection>
        </>
      )}
    </NextStepsWrapper>
  );
};
