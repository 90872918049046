import { MsgValidation } from "c4u-web-components";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { IVehicleDanfeForm } from "../../../models";

export const VehicleDanfeValidationSchema = () => {
  const { t } = useTranslation();

  const msgRequired = t(MsgValidation.RequiredField);

  const validations = Yup.object<IVehicleDanfeForm>({
    invoiceKey: Yup.string().required(msgRequired),
  });

  return validations;
};
