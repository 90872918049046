import { CoxIcon } from "c4u-web-components";
import React from "react";
import {
  CardHeaderTitleWrapper,
  HeaderTitleCard,
  VehicleIdWrapper,
  VehicleStatus,
} from "./card-header.atom.style";

interface IProps {
  companyLogoImage: React.ReactElement;
  fillColor: string;
  statusIconName: string;
  statusText: string;
  headerTitle: string;
}

export const CardHeaderAtom: React.FC<IProps> = (props) => {
  return (
    <CardHeaderTitleWrapper>
      <VehicleIdWrapper>
        {props.companyLogoImage}
        <HeaderTitleCard>{props.headerTitle}</HeaderTitleCard>
      </VehicleIdWrapper>

      <VehicleStatus color={props.fillColor}>
        {props.statusIconName && (
          <CoxIcon
            name={props.statusIconName}
            fillColor={props.fillColor}
            width={16}
            height={16}
          />
        )}
        {props.statusText}
      </VehicleStatus>
    </CardHeaderTitleWrapper>
  );
};
