import styled from "styled-components";

export const AppContainerWithMenu = styled.div`
  padding: 60px 0 40px 82px;
  min-height: 100%;
  width: 100%;

  @media (max-width: 575px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  background: ${(props) => props.theme.colors.Background};

  overflow: hidden;
`;
