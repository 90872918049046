import styled from "styled-components";

export const WrapperUploadPdf = styled.div`
  background: #f9f9f9;
  border: 1px solid rgba(168, 170, 172, 0.35);
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  height: 350px;
  display: inline-block;
`;

export const DivPdf = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 45%;
`;

export const DivUploadPdf = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
`;

export const ImgUploadPdf = styled.img`
  height: 48px;
  width: 48px;
  margin-left: auto;
  margin-right: auto;
`;

export const ParagrafoUploadPdf = styled.p`
  text-align: center;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #4a4a4a;
`;

export const FileInputUploadPdf = styled.input`
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 350px;
  display: inline-block;
  border-radius: 10px;
  border: 1px solid rgba(168, 170, 172, 0.35);
`;

export const FilePreviewPdf = styled.div`
  width: 100%;
  height: 105px;
  position: relative;
`;

export const ButtonWrapperFilePreviewPdf = styled.div`
  padding: 5px 15px 10px;
  border-top: 0;

  button {
    color: ${(props) => props.theme.colors.DarkBlue};

    width: 100%;
    margin: 5px 14px;

    height: 24px;
    padding: 0 10px;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-direction: column;
`;
