import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonPrimary, ButtonSecondary } from "c4u-web-components";
import {
  WrapperUploadImage,
  TitleUploadImage,
  ImageWrapperDocument,
  ImageUpload,
  ImagePreviewtUpload,
  TextBoldUploadImage,
  TextNormalUploadImage,
  ButtonWrapperUploadImage,
  FileInputUploadImage,
  ButtonWrapperPreviewUploadImage,
} from "./upload-document-image.atom.style";
import { useRenaveContext } from "../../../hooks";
import {
  IDocumentModel,
  TypeIndexDocumentEnum,
  TypeMethodSendingAtpveEnum,
} from "../../../models";

interface IProps {
  icon: any;
  title: string;
  index: number;
  isSubmitting?: boolean;
  setValidDocument: (v: boolean) => void;
  disabled?: boolean;
}

export const UploadDocumentImageAtom: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const { setDocumentContext, documentContext } = useRenaveContext();

  const { icon, title, index, isSubmitting, setValidDocument } = props;

  const fileInput = useRef(document.createElement("input"));

  const [selectedFile, setSelectedFiles] = useState<any>();
  const [currentFile, setCurrentFile] = useState<string>();

  const validImage = (img: any) => {
    const allowedTypes = ["image/png", "image/jpeg"];
    const allowedSize = 7340032;

    return (
      img &&
      allowedTypes.find((type) => img.type === type) &&
      allowedSize >= img.size
    );
  };

  const selectFile = (event: any) => {
    const files = event.target.files;
    if (validImage(files[0])) {
      setSelectedFiles(files[0]);
    } else {
      //invalid image
      //TODO: redimensionar a imagem
    }
  };

  useEffect(() => {
    const upload = () => {
      const file = selectedFile;

      if (!file) return;

      var valuesDocumentContext: IDocumentModel = documentContext;

      if (!valuesDocumentContext) {
        valuesDocumentContext = {
          typeMethodSending: TypeMethodSendingAtpveEnum.FrontAndBackFiles,
          file: [],
        };
      }

      const indexExistentFile = valuesDocumentContext?.file?.findIndex(
        (x) => x.index === index
      );

      if (indexExistentFile > -1)
        valuesDocumentContext.file.splice(indexExistentFile, 1);

      const indexExistentPdfFile = valuesDocumentContext?.file?.findIndex(
        (x) => x.index === TypeIndexDocumentEnum.PdfDocument
      );

      if (indexExistentPdfFile > -1)
        valuesDocumentContext.file.splice(indexExistentPdfFile, 1);

      const urlImage = URL.createObjectURL(file);
      const image = new Image();

      image.onload = () => {
        valuesDocumentContext.file.push({
          file: file,
          title: title,
          index: index,
          width: image.width,
          height: image.height,
        });
      };

      image.src = urlImage;

      setDocumentContext(valuesDocumentContext);
      setCurrentFile(urlImage);
      setValidDocument(false);
    };

    if (selectedFile) upload();
  }, [selectedFile]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (documentContext) {
      const item = documentContext.file.find((x) => x.index === index);

      if (item) setCurrentFile(URL.createObjectURL(item.file));
    }
  }, [documentContext]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <WrapperUploadImage>
      <TitleUploadImage>{t(title ?? "")}</TitleUploadImage>

      {!currentFile ? (
        <>
          <ImageWrapperDocument>
            <FileInputUploadImage
              accept=".png,.jpeg,.jpg"
              onChange={(ev) => selectFile(ev)}
              ref={fileInput}
              type="file"
              disabled={isSubmitting || !!props.disabled}
            />
            {icon && <ImageUpload src={icon} alt="front" />}
          </ImageWrapperDocument>

          <ButtonWrapperUploadImage>
            <TextBoldUploadImage>{t("add photo info")}</TextBoldUploadImage>
            <TextNormalUploadImage>
              {t("add photo formats")}
            </TextNormalUploadImage>

            <ButtonPrimary
              onClick={() => fileInput.current.click()}
              sizey="thin"
              disabled={isSubmitting || !!props.disabled}
            >
              {t("send picture")}
            </ButtonPrimary>
          </ButtonWrapperUploadImage>
        </>
      ) : (
        <>
          <ImagePreviewtUpload backgroundImage={currentFile}>
            <FileInputUploadImage
              accept=".png,.jpeg,.jpg"
              onChange={(ev) => selectFile(ev)}
              ref={fileInput}
              type="file"
              disabled={isSubmitting || !!props.disabled}
            />
          </ImagePreviewtUpload>

          <ButtonWrapperPreviewUploadImage>
            <ButtonSecondary
              sizex={"md"}
              sizey={"thin"}
              disabled={isSubmitting || !!props.disabled}
              onClick={() => fileInput.current.click()}
            >
              {t("change picture")}
            </ButtonSecondary>
          </ButtonWrapperPreviewUploadImage>
        </>
      )}
    </WrapperUploadImage>
  );
};
