import { TypesHelper } from "c4u-web-components";
import { ReturnZeroKmReasonsEnum } from "../..";

export interface IPostReturnZeroKmRequest {
  id: number;
  returnDate: Date;
  invoiceKey: string;
  returnZeroKmReason: ReturnZeroKmReasonsEnum;
}

export class PostReturnZeroKmRequest {
  id: number | null;
  returnDate: Date | null;
  invoiceKey: string | null;
  returnZeroKmReason: ReturnZeroKmReasonsEnum | null;
  constructor({
    id,
    returnDate,
    invoiceKey,
    returnZeroKmReason,
  }: IPostReturnZeroKmRequest) {
    this.id = TypesHelper.toNumberNull(id);
    this.returnDate = TypesHelper.toDateNull(returnDate);
    this.invoiceKey = TypesHelper.toStringOnlyNumbersNull(invoiceKey);
    this.returnZeroKmReason = TypesHelper.toNumberNull(returnZeroKmReason);
  }
}
