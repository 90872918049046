import { TypesHelper } from "c4u-web-components";
import { TypeDocumentCodeEnum } from "../..";

export interface IGetDocumentRequest {
  id: number;
  urlNotificationReturn?: string;
  typeDocumentCode: TypeDocumentCodeEnum;
}

export class GetDocumentRequest {
  id: number | null;
  urlNotificationReturn: string | null;
  typeDocumentCode: TypeDocumentCodeEnum | null;

  constructor({
    id,
    urlNotificationReturn,
    typeDocumentCode,
  }: IGetDocumentRequest) {
    this.id = TypesHelper.toNumberNull(id);
    this.urlNotificationReturn = TypesHelper.toStringNull(
      urlNotificationReturn
    );
    this.typeDocumentCode = TypesHelper.toNumberNull(typeDocumentCode);
  }
}
