import { IFindByPlateResponse } from "../../../models";
import { useRequest, renaveHttp } from "../config";

export const useVehicle = () => {
  const { get } = useRequest(renaveHttp, "Vehicle");

  const getVehicleByPlate = async (plate: string) => {
    const { data } = await get<IFindByPlateResponse>(`findByPlate/${plate}`);
    return data;
  };

  return {
    getVehicleByPlate,
  };
};
