import styled from "styled-components";

const CreditStatementTextStyleBase = styled.div`
  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 700;
  padding: 10px;
`;

export const CreditStatementWrapper = styled.div`
  background: rgba(168, 170, 172, 0.35);
  border-radius: 10px;
`;

export const CreditStatementText = styled(CreditStatementTextStyleBase)`
  color: ${(props) => props.theme.colors.Blue40};
  font-size: 12px;
  line-height: 20px;
`;

export const CreditStatementMoneyText = styled(CreditStatementTextStyleBase)`
  color: ${(props) => props.theme.colors.Confirm};
  font-size: 12px;
  line-height: 20px;
`;

export const CreditStatementNegativeMoneyText = styled(
  CreditStatementTextStyleBase
)`
  color: ${(props) => props.theme.colors.Error};
  font-size: 12px;
  line-height: 20px;
`;
