import { IGetDocumentResponse } from "../models";

export class FileHelper {
  static downloadBase64PdfFile(
    onClickGetDocument: () => Promise<IGetDocumentResponse>,
    window: Window & typeof globalThis,
    showErrorModal?: () => void,
    setIsLoading?: (value: boolean) => void
  ) {
    onClickGetDocument()
      .then(async (data) => {
        if (data !== undefined && data.base64File !== null) {
          const base64Response = await fetch(
            `data:application/pdf;base64,${data.base64File}`
          );

          const blob = await base64Response.blob();
          const url = window.URL.createObjectURL(blob);
          window.open(url as any, "_blank");
        }
      })
      .catch((e) => {
        console.log("error downloading file", e);
        showErrorModal && showErrorModal();
      })
      .finally(() => {
        setIsLoading && setIsLoading(false);
      });
  }
}
