import styled from "styled-components";

const WrapperCardBodyRenave = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 25px;
`;

const CardBodyTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  color: ${({ theme }) => theme.colors.BlueNavy};
`;

const CardDate = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;

  white-space: nowrap;
  color: ${({ theme }) => theme.colors.Dark};
`;

const CardBodySubtitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;

  color: ${({ theme }) => theme.colors.BlueNavy};
  margin-top: 7px;
`;

const CardBodyWrapperButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  margin-left: auto;
`;

export {
  WrapperCardBodyRenave,
  CardDate,
  CardBodySubtitle,
  CardBodyTitle,
  CardBodyWrapperButtons,
};
