export enum RejectZeroKmReasonsEnum {
  ForgoEntry = 1,
  MakerOperationalError,
}

export enum ReturnZeroKmReasonsEnum {
  ReturnForTransforming = 1,
  ReturnForFixingRegisterData,
  DamagesOrDefects,
  DirectSale,
}
