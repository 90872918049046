import styled from "styled-components";

export const NextStepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;

  padding-left: 53px;
`;

export const InformativeSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;

  color: ${(props) => props.theme.colors.Dark};
`;

export const ListItensSection = styled.div`
  display: flex;
  flex-direction: column;

  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  ul {
    list-style: initial;
    padding: 0 0 0 20px;
  }

  color: ${(props) => props.theme.colors.Dark};
`;
