import { Form } from "react-bootstrap";
import styled from "styled-components";

export const RejectModalFormRow = styled(Form.Row)`
  width: 360px;
  margin: 10px 0 60px;
  .col {
    padding: 0;
  }
  label {
    font-weight: 700;
  }
`;

export const RejectFormButtonsGroup = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
