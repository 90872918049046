import styled, { css } from "styled-components";
import { ISizeProp } from "../../../models";

export const BalanceSummaryWrapper = styled.div<{ size?: ISizeProp }>`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 182px;
  ${({ size }) =>
    size === "sm"
      ? css`
          min-height: 60px;
        `
      : size === "md"
      ? css`
          min-height: 76px;
        `
      : css`
          min-height: 80px;
        `};
`;

export const LineBalanceHr = styled.hr`
  width: 2px;
  height: 50px;
  margin: 0;
  border: 0;
  border-left: 2px solid #e1e1e2;
  box-sizing: border-box;
`;

export const BalanceSummaryCard = styled.div<{ size?: ISizeProp }>`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 20px;
  padding: 15px 20px;
  border-radius: 10px;

  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);

  ${({ size }) =>
    size === "sm"
      ? css`
          gap: 15px;
          padding: 10px 15px;
          hr {
            height: 40px;
          }
        `
      : size === "md"
      ? css`
          gap: 18px;
          padding: 13px 18px;
        `
      : ""};
`;
