import { transparentize } from "polished";
import styled from "styled-components";

export const OnScreenMsgWrapper = styled.div<{ borderColor: string }>`
  display: flex;
  align-items: center;
  padding: 10px 10px;

  gap: 13px;

  background: ${(props) => transparentize(0.85, props.borderColor)};

  border: 1px solid ${(props) => props.borderColor};
  border-radius: 8px;
`;

export const OnScreenMsgText = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;

  color: ${(props) => props.theme.colors.Dark};
`;
