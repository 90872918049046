import styled from "styled-components";

export const PageControlItem = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  width: 38px;

  font-family: ${(props) => props.theme.fontFamily.OpenSans};
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;

  color: #535353;
  background: #f2f2f3;
  border: none;
  border-radius: 10px;

  cursor: ${(props) => (props.onClick ? "pointer" : "default")} !important;

  &:disabled {
    opacity: 0.4;
    cursor: default !important;
  }

  &.selected {
    color: #ffffff;
    background: #6ac2fb;
    cursor: default;
  }
`;
