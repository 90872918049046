import styled from "styled-components";

export const CardHeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;

  @media (max-width: 767px) {
    flex-direction: column;
    gap: 12px;
  }
`;

export const VehicleIdWrapper = styled.div`
  display: flex;
  gap: 32px;
`;

const HeaderBaseFont = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.Montserrat};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  white-space: nowrap;
`;

export const HeaderTitleCard = styled(HeaderBaseFont)`
  color: ${({ theme }) => theme.colors.BlueNavy};
`;

export const VehicleStatus = styled(HeaderBaseFont)`
  display: inline-flex;
  align-items: center;
  gap: 8px;

  white-space: nowrap;

  text-transform: uppercase;
  color: ${(props) => props.color};
`;
