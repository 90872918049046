import styled from "styled-components";

export const SelectVehicleDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const SelectVehicleContentLoader = styled.div`
  display: flex;
  gap: 10px;

  .spinner-border {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const SelectVehicleDescription = styled.div`
  font-family: ${(props) => props.theme.fontFamily.Helvetica};
  font-weight: bold;
  font-size: 13px;
  line-height: 28px;
  color: ${(props) => props.theme.colors.BlueNavy};
`;

export const SelectVehicleName = styled.div<{ isError?: boolean }>`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-weight: bold;
  font-size: ${(props) => (props.isError ? "14px" : "18px")};
  line-height: 30px;
  color: ${(props) => props.theme.colors.Dark};
`;
