import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CreditStatementHeaderList } from "./credit-statement-header.molecule.style";

export const CreditStatementHeaderMolecule: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Col sm={{ span: 10, offset: 1 }}>
      <Row className="text-center">
        <Col sm={2}>
          <CreditStatementHeaderList>{t("Date")}</CreditStatementHeaderList>
        </Col>
        <Col sm={4}>
          <CreditStatementHeaderList>
            {t("Description")}
          </CreditStatementHeaderList>
        </Col>
        <Col sm={3}>
          <CreditStatementHeaderList>
            {t("PlateAndChassi")}
          </CreditStatementHeaderList>
        </Col>
        <Col sm={3}>
          <CreditStatementHeaderList>{t("Value")}</CreditStatementHeaderList>
        </Col>
      </Row>
    </Col>
  );
};
