import { TypesHelper } from "c4u-web-components";
import { TypeDocumentEnum, CheckoutTypeEnum } from "../..";

export interface IPostCustomerCheckOutZeroKmRequest {
  id: number;
  saleValue?: number;
  buyerDocumentType?: TypeDocumentEnum;
  buyerDocument?: string;
  buyerName?: string;
  buyerEmail?: string;
  buyerZipCode?: string;
  buyerStreet?: string;
  buyerNumber?: string;
  buyerComplement?: string;
  buyerNeighborhood?: string;
  buyerCityCode?: string;
  buyerCity?: string;
  buyerUF?: string;
  stockExitDate?: Date;
  checkOutInvoiceKey?: string;
  checkOutInvoiceKeyDate?: Date;
  checkOutZeroKmType?: CheckoutTypeEnum;
  dealershipEmail?: string;
}

export class PostCustomerCheckOutZeroKmRequest {
  id: number | null;
  saleValue: number | null;
  buyerDocumentType: TypeDocumentEnum | null;
  buyerDocument: string | null;
  buyerName: string | null;
  buyerEmail: string | null;
  buyerZipCode: string | null;
  buyerStreet: string | null;
  buyerNumber: string | null;
  buyerComplement: string | null;
  buyerNeighborhood: string | null;
  buyerCityCode: string | null;
  buyerCity: string | null;
  buyerUF: string | null;
  stockExitDate: Date | null;
  checkOutInvoiceKey: string | null;
  checkOutInvoiceKeyDate: Date | null;
  checkOutZeroKmType: CheckoutTypeEnum | null;

  constructor({
    id,
    saleValue,
    buyerDocumentType,
    buyerDocument,
    buyerName,
    buyerEmail,
    buyerComplement,
    buyerZipCode,
    buyerStreet,
    buyerNumber,
    buyerNeighborhood,
    buyerCityCode,
    buyerCity,
    buyerUF,
    stockExitDate,
    checkOutInvoiceKey,
    checkOutInvoiceKeyDate,
  }: IPostCustomerCheckOutZeroKmRequest) {
    this.id = TypesHelper.toNumberNull(id);
    this.saleValue = TypesHelper.toNumberNull(saleValue);
    this.buyerDocumentType = TypesHelper.toNumberNull(buyerDocumentType);
    this.buyerDocument = TypesHelper.toStringOnlyNumbersNull(buyerDocument);
    this.buyerName = TypesHelper.toStringNull(buyerName);
    this.buyerEmail = TypesHelper.toStringNull(buyerEmail);
    this.buyerZipCode = TypesHelper.toStringOnlyNumbersNull(buyerZipCode);
    this.buyerStreet = TypesHelper.toStringNull(buyerStreet);
    this.buyerNumber = TypesHelper.toStringNull(buyerNumber);
    this.buyerComplement = TypesHelper.toStringNull(buyerComplement);
    this.buyerNeighborhood = TypesHelper.toStringNull(buyerNeighborhood);
    this.buyerCityCode = TypesHelper.toStringNull(buyerCityCode);
    this.buyerCity = TypesHelper.toStringNull(buyerCity);
    this.buyerUF = TypesHelper.toStringNull(buyerUF);
    this.stockExitDate = TypesHelper.toDateNull(stockExitDate);
    this.checkOutInvoiceKey = TypesHelper.toStringNull(checkOutInvoiceKey);
    this.checkOutInvoiceKeyDate = TypesHelper.toDateNull(
      checkOutInvoiceKeyDate
    );
    this.checkOutZeroKmType = CheckoutTypeEnum.Exit;
  }
}
