import { TypesHelper } from "c4u-web-components";

export interface IGetStatusRequest {
  id: number;
}

export class GetStatusRequest {
  id: number | null;

  constructor({ id }: IGetStatusRequest) {
    this.id = TypesHelper.toNumberNull(id);
  }
}
