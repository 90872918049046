import styled from "styled-components";

export const BalanceAndCreditsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  margin: 12px 0;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

export const CreditButtonsWrapper = styled.div`
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  gap: 12px 18px;

  button {
    margin: unset;
  }

  /* margin-left: auto; */
`;
