import {
  CorporateUserDealershipLogRequest,
  HttpHelper,
  IAuth0User,
  ICoxUser,
  IListDealershipsPagedResponse,
  ListDealershipsPagedParams,
} from "c4u-web-components";
import { IDealership } from "../../../models";
import { useRequest, c4uHttp } from "./../config";

export const useC4u = () => {
  const { get, post } = useRequest(c4uHttp, "");

  const GetAdminUser = async () => {
    const { data } = await get<ICoxUser>(`Admin/User`);
    return data;
  };

  const GetAuth0User = async () => {
    const { data } = await get<IAuth0User>(`Admin/Auth0User`);
    return data;
  };

  const GetDealership = async (id: number) => {
    const { data } = await get<IDealership>(`Dealership/${id}`);
    return data;
  };

  const postSelectDealership = async (
    request: CorporateUserDealershipLogRequest
  ) => {
    await post("LoginLog/createCorporateUserDealershipLog", request);
  };

  const getListMyDealerships = async (params: ListDealershipsPagedParams) => {
    const { data } = await get<IListDealershipsPagedResponse>(
      `Admin/listMyDealerships${HttpHelper.BuildQueryString(params)}`
    );
    return data;
  };

  return {
    GetAuth0User,
    GetDealership,
    GetAdminUser,
    postSelectDealership,
    getListMyDealerships,
  };
};
