import styled from "styled-components";

export const WrapperCardDiv = styled.div`
  display: flex;
  flex-direction: column;

  gap: 24px;

  @media (min-width: 1200px) {
    margin-left: 102px;
  }
`;

export const CardSectionHR = styled.hr`
  width: 100%;
  border: 1px dashed ${(props) => props.theme.colors.DarkGrayOpacity};
`;
