import { TypesHelper } from "c4u-web-components";
import { RenaveStatusEnum } from "../..";

export interface IGetVehicleRequest {
  search?: string;
  page: number;
  pageSize: number;
  startDate?: string | null;
  finishDate?: string | null;
  step?: RenaveStatusEnum | "";
  updateZeroKm?: boolean;
}

export class GetVehicleRequest {
  search: string | null;
  page: number | null;
  pageSize: number | null;
  startDate: Date | null;
  finishDate: Date | null;
  step: RenaveStatusEnum | null;
  updateZeroKm: boolean | null;
  constructor({
    search,
    page,
    pageSize,
    startDate,
    finishDate,
    step,
    updateZeroKm,
  }: IGetVehicleRequest) {
    this.search = TypesHelper.toStringNull(search);
    this.page = TypesHelper.toNumberNull(page);
    this.pageSize = TypesHelper.toNumberNull(pageSize);
    this.startDate = TypesHelper.toDateTimeNull(startDate);
    this.finishDate = TypesHelper.toDateTimeNull(finishDate);
    this.step = TypesHelper.toNumberNull(step !== "" ? step : null);
    this.updateZeroKm = TypesHelper.toBooleanNull(updateZeroKm);
  }
}

export interface IGetVehicleResponse {
  id: number;
  step: RenaveStatusEnum;
  plate?: string;
  stockEntryDate?: string;
  stockExitDate?: string;
  renavam?: string;
  controlStockId?: number;
  vin?: string;
  isZeroKm?: boolean;
}

export interface IPagedResponseBase {
  currentPage: number;
  totalPages: number;
  recordsPerPage: number;
  totalRecords: number;
}

export interface IGetVehiclePagedResponse extends IPagedResponseBase {
  data?: IGetVehicleResponse[];
}
