import { Col } from "react-bootstrap";
import styled from "styled-components";

export const WrapperRegisterDealershipPage = styled.div`
  display: flex;
  flex-direction: column;

  gap: 35px;

  @media (min-width: 576px) {
    padding: 0 0 0 75px;
  }
`;

export const TitleOrganismDiv = styled(Col)`
  font-family: ${(props) => props.theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;

  letter-spacing: -0.15px;

  color: ${(props) => props.theme.colors.BlueNavy};
`;
